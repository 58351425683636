/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { noCase } from "change-case";
import { useState } from "react";
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from "@mui/material";
// utils
import { fToNow } from "../../../utils/formatTime";

// components
import Iconify from "../../../components/Iconify";
import Scrollbar from "../../../components/Scrollbar";
import MenuPopover from "../../../components/MenuPopover";
import { IconButtonAnimate } from "../../../components/animate";

import { useEffect } from "react";

import { useNavigate } from "react-router";
import {
  PATH_ADMIN_DASHBOARD,
  PATH_PATIENT_DASHBOARD,
  PATH_PROVIDER_DASHBOARD,
} from "../../../routes/paths";
import ReadNotification from "../../../sections/@dashboard/user/ReadNotification";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../utils/axios";
import socket from "../../../utils/socket";
import { useRef } from "react";

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const componentMounted = useRef(true);

  const [notifications, setNotifications] = useState([]);

  const totalUnRead = notifications.filter(
    (item) => item.isUnRead === 1
  ).length;

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = async () => {
    try {
      const response = await axios.post(`/notifiction-seen-status-update-all`, {
        receiver_user_id: user?.id,
      });
      if (response.data.Message) {
        getnotification();
        setNotifications(
          notifications.map((notification) => ({
            ...notification,
            isUnRead: false,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNotificationRead = async (id, type, action) => {
    // alert(id);
    try {
      const response = await axios.post(`/notifiction-seen-status-update`, {
        receiver_user_id: user?.id,
        id: id,
      });
      if (response.data.Message) {
        getnotification();
        setNotifications(
          notifications.map((notification) => ({
            ...notification,
            isUnRead: false,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
    if (type === "Appointment") {
      if (user?.role === 1) {
        navigate(PATH_ADMIN_DASHBOARD.general.appointment);
      }
      if (user?.role === 2) {
        navigate(PATH_PROVIDER_DASHBOARD.general.booking);
      }
      if (user?.role === 3) {
        navigate(PATH_PATIENT_DASHBOARD.general.appointment);
      }
    }
    if (type === "Chat") {

      if (user?.role === 1) {
        navigate(`${PATH_ADMIN_DASHBOARD.chat.root}${action}`);
      }
      if (user?.role === 2) {
        navigate(`${PATH_PROVIDER_DASHBOARD.chat.root}${action}`);
      }
      if (user?.role === 3) {
        navigate(`${PATH_PATIENT_DASHBOARD.chat.root}${action}`);
      }
    }
    if (type === "Contact") {
      if (user?.role === 1) {
        navigate(PATH_ADMIN_DASHBOARD.general.contact);
      }
    }

    setOpen(null);
  };

  const getnotification = async () => {
    try {
      const response = await axios.get(`/get-user-notification/${user?.id}`);
      console.log("NotificationsPopover.js");
      setNotifications(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getnotification();
    if (componentMounted.current) {
      socket.on("getNotification", (res) => {
        console.log(socket.id, "connected NotificationsPopover.js", res);
        axios.get(`/get-user-notification/${user.id}`).then((res) => {

          if (res.data.Status !== false) {
            setNotifications(res.data);
            componentMounted.current = false;
          }
        });
      });
      axios
        .get(`/get-user-notification/${user.id}`)
        .then((res) => {
          if (res.data.Status !== false) {
            setNotifications(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const viewAll = () => {
    if (user?.role === 1) {
      setOpen(null);
      navigate(PATH_ADMIN_DASHBOARD.general.notification);
    }
    if (user?.role === 2) {
      setOpen(null);
      navigate(PATH_PROVIDER_DASHBOARD.general.notification);
    }
    if (user?.role === 3) {
      setOpen(null);
      navigate(PATH_PATIENT_DASHBOARD.general.notification);
    }
  };

  return (
    <>
      <IconButtonAnimate
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {/* You have {totalUnRead} unread messages */}
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                New
              </ListSubheader>
            }
          >
            {notifications.slice(0, 2).map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                handleNotificationRead={handleNotificationRead}
              />
            ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                Previous
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                handleNotificationRead={handleNotificationRead}
              />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple onClick={viewAll}>
            View All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    // created_at: PropTypes.instanceOf(Date),
    id: PropTypes.any,
    isUnRead: PropTypes.any,
    title: PropTypes.any,
    description: PropTypes.any,
    type: PropTypes.any,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification, handleNotificationRead }) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(notification.isUnRead === 1 && {
          bgcolor: "action.selected",
        }),
      }}
      onClick={() => {
        handleNotificationRead(notification.id, notification.type, notification.action);
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <Iconify
              icon="eva:clock-outline"
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {fToNow(notification.created_at)}
            {/* {notification.created_at} */}
            <ReadNotification data={notification} />
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <div
      style={{
        width: "250px !important",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100px !important",
        }}
        nowrap="false"
      >
        {notification.title}
      </Typography>
      <br />
      <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100px !important",
        }}
        nowrap="false"
      >
        &nbsp; {noCase(notification.description)}
      </Typography>
    </div>
  );
  if (notification.type === "Appointment") {
    return {
      avatar: (
        <Iconify
          icon={"teenyicons:appointments-outline"}
          width={24}
          height={24}
        />
      ),
      title,
    };
  }
  if (notification.type === "Contact") {
    return {
      avatar: <Iconify icon={"bxs:contact"} width={24} height={24} />,
      title,
    };
  }
  if (notification.type === "order_placed") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_package.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === "order_shipped") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_shipping.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === "mail") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_mail.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === "Chat") {
    return {
      avatar: <Iconify icon={"ic:outline-mark-chat-read"} width={24} height={24} sx={{ color: '#6495ed' }} />,
      title,
    };
  }
  return {
    avatar: notification.avatar ? (
      <img alt={notification.title} src={notification.avatar} />
    ) : null,
    title,
  };
}
