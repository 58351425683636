function isPushNotificationSupported() {
  return "serviceWorker" in navigator && "PushManager" in window;
}

async function askUserPermission() {
  return await Notification.requestPermission();
}
function registerServiceWorker() {
  return navigator.serviceWorker.register(`${process.env.REACT_APP_PUBLIC_URL}/sw.js`);
}
function sendNotification(data) {
  isPushNotificationSupported();
  askUserPermission();
  registerServiceWorker();
  console.log("push-notifications.js , sendNotification",);
  //console.log(data);
  const text = `${data.description}`;
  const title = `${data.title}`;
  const options = {
    body: text,
    icon: "https://droupons.com/favicon/apple-touch-icon.png",
    vibrate: [300, 100, 400],
    tag: `${data.title}`,
    // image: img,
    badge: `https://droupons.com/favicon/apple-touch-icon.png`,

    actions: [
      {
        action: `../../notification`,
        url: `../../notification`,
        title: "View",
        // icon: "https://droupons.com/favicon/apple-touch-icon.png",
      },
    ],
  };
  console.log(options, "optionsoptionsoptionsoptions push-notifications.js ,");
  navigator.serviceWorker.ready.then(function (serviceWorker) {
    serviceWorker.showNotification(title, options);
  });
}



export {
  isPushNotificationSupported,
  askUserPermission,
  registerServiceWorker,
  sendNotification,
};
