
// @mui
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  AppBar,
  Toolbar,
  Container,
  Stack,

  Typography,
} from "@mui/material";
// hooks
import useOffSetTop from "../../hooks/useOffSetTop";

// utils
import cssStyles from "../../utils/cssStyles";
// config
import { HEADER } from "../../config";
// components
import Logo from "../../components/Logo";
//
// import MenuDesktop from "./MenuDesktop";
// import MenuMobile from "./MenuMobile";
// import navConfig from "./MenuConfig";
// import Cart from "../../pages/WellnessStore/Cart";

import HeaderAccountPopover from "./HeaderAccountPopover";
// import NotificationsPopover from "../dashboard/header/NotificationsPopover";
// import useAuth from "../../hooks/useAuth";
// import DeleteIcon from "@mui/icons-material/Delete";
import Iconify from "../../components/Iconify";
import React from "react";
import ProviderTypeMenu from "./ProviderTypeMenu";
// import LanguagePopover from "../dashboard/header/LanguagePopover";
import { NavLink as RouterLink } from "react-router-dom";
import { PATH_AUTH, PATH_PAGE } from "../../routes/paths";
import FilterBar from "../../sections/home/Filter";

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(["height", "background-color"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("md")]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: "auto",
  borderRadius: "50%",
  position: "absolute",
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

export default function MainHeader() {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);


  const theme = useTheme();
  const isHome = "/";

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: "transparent" }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
        className="header"
      >
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: '5px',
            paddingRight: '5px'
          }}
        >
          <div className='head_menu_logo' >
            <Logo />
          </div>


          {window.location.pathname === isHome ? (
            <Box
              sx={{
                padding: "5px",
                border: "0px",
                boxShadow:
                  "0 1px 2px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 5%)",
                borderRadius: "20px",
                display: "flex",
              }}
              className='search_box'
            >
              <div style={{ padding: "7px" }}>
                <Typography
                  variant="p"
                  sx={{
                    color: "#637381",
                    fontSize: "0.875rem",
                    paddingLeft: "0px",
                    paddingRight: "10px",
                    borderRight: "2px solid rgb(164 170 173)",
                  }}
                >
                  Services <>&nbsp;</>
                </Typography>
              </div>
              <div style={{ padding: "7px" }}>
                <Typography
                  variant="p"
                  sx={{
                    color: "#637381",
                    fontSize: "0.875rem",
                    paddingRight: "10px",
                    borderRight: "2px solid rgb(164 170 173)",
                  }}
                >
                  Any Time
                </Typography>
              </div>
              <div style={{ padding: "7px" }}>
                <Typography
                  variant="p"
                  sx={{ color: "#637381", fontSize: "0.875rem" }}
                >
                  Your Location
                </Typography>
              </div>

              <div
                style={{
                  backgroundColor: "#6495ED",
                  borderRadius: "100%",
                  display: "flex",
                  alignItems: "center",
                  width: "40px",
                  height: "40px",
                }}
              >
                <Iconify
                  icon={"ci:search"}
                  width={25}
                  height={25}
                  sx={{ margin: "0px auto", color: "#ffffff" }}
                />
              </div>
            </Box>
          ) : null}

          {/* {isDesktop && <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />} */}
          <Stack
            direction="row"
            alignItems="center"
            spacing={{ xs: 0.5, sm: 1.5 }}
          >
            {/* <Cart /> {UserRole === undefined ? null : <NotificationsPopover />} */}
            <div style={{ padding: "7px" }} className='head_menu'>
              <Typography
                variant="p"
                className="head_menu_button"
                component={RouterLink}
                to={PATH_PAGE.contact}
                sx={{
                  color: "#6495ED",
                  fontFamily: "M PLUS Rounded 1c',sans-serif",
                }}
              >
                Help
              </Typography>
              <Typography
                variant="p"
                sx={{

                  fontSize: "0.875rem",
                }}
              >
                |
              </Typography>
              <Typography
                variant="p"
                className="head_menu_button"
                component={RouterLink}
                to={PATH_AUTH.BecomeaProvider}
                style={{
                  color: "#6495ED",
                  // fontFamily: "M PLUS Rounded 1c, sans-serif",
                }}
              >
                Become a Provider
              </Typography>
            </div>
            {/* <LanguagePopover /> */}
            <Box
              sx={{
                padding: "5px",
                border: "0px",
                boxShadow:
                  "0 1px 2px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 5%)",
                borderRadius: "20px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <HeaderAccountPopover />
              </div>
            </Box>
          </Stack>

          {/* {!isDesktop && (
            <MenuMobile
              isOffset={isOffset}
              isHome={isHome}
              navConfig={navConfig}
            />
          )} */}
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
      {window.location.pathname === isHome ? (
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "15px",
            paddingTop: "15px",
            paddingLeft: "5px",
            paddingRight: "5px",
          }}
        >
          <ProviderTypeMenu />
          <FilterBar />

        </Container>
      ) : null}
    </AppBar>
  );
}
