import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Link, Typography } from "@mui/material";
// hooks
import useAuth from "../../../hooks/useAuth";
// routes
import {
  PATH_ADMIN_DASHBOARD,
  PATH_PATIENT_DASHBOARD,
  PATH_PROVIDER_DASHBOARD,
} from "../../../routes/paths";
// components
import MyAvatar from "../../../components/MyAvatar";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const { user } = useAuth();
  const UserRole = user.role;

  return (
    <Link
      underline="none"
      color="inherit"
      component={RouterLink}
      to={(() => {
        if (UserRole === 1) {
          return PATH_ADMIN_DASHBOARD.setting;
        }
        if (UserRole === 2) {
          return PATH_PROVIDER_DASHBOARD.user.profile;
        }
        if (UserRole === 3) {
          return PATH_PATIENT_DASHBOARD.user.profile;
        }
      })()}
    >
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: "transparent",
          }),
        }}
      >
        <MyAvatar />

        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create("width", {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          {isCollapse ? null : (
            <>
              {(() => {
                if (UserRole === 1) {
                  return (
                    <>
                      <Typography variant="subtitle2" nowrap={true.toString()}>
                        {user?.lastName}
                      </Typography>
                      <Typography
                        variant="body2"
                        nowrap={true.toString()}
                        sx={{
                          color: "text.secondary",
                          textTransform: "capitalize",
                        }}
                      >
                        {user?.USER_ROLE_NAME}
                      </Typography>
                    </>
                  );
                }
                if (UserRole === 2) {
                  return (
                    <>
                      <Typography variant="subtitle2" nowrap={true.toString()}>
                        Dr. {user?.lastName}
                      </Typography>
                      <Typography
                        variant="body2"
                        nowrap={true.toString()}
                        sx={{ color: "text.secondary" }}
                      >
                        {user?.PROVIDER_ROLE_NAME}
                      </Typography>
                    </>
                  );
                }
                if (UserRole === 3) {
                  return (
                    <>
                      <Typography variant="subtitle2" nowrap={true.toString()}>
                        {user?.displayName}
                      </Typography>
                      <Typography
                        variant="body2"
                        nowrap={true.toString()}
                        sx={{ color: "text.secondary" }}
                      >
                        {user?.PATIENT_ROLE_NAME !== ""
                          ? "Patient"
                          : user?.PATIENT_ROLE_NAME}
                      </Typography>
                    </>
                  );
                }
              })()}
            </>
          )}
        </Box>
      </RootStyle>
    </Link>
  );
}
