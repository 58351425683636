import { memo } from "react";
// @mui
import { styled } from "@mui/material/styles";
import { Container } from "@mui/material";
// config
import { HEADER } from "../../../config";
// components
import { NavSectionHorizontal } from "../../../components/nav-section";
//
import navConfig from "./AdminNavConfig";
import ProvidernavConfig from "./ProviderNavConfig";
import useAuth from "../../../hooks/useAuth";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  transition: theme.transitions.create("top", {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  width: "100%",
  position: "fixed",
  zIndex: theme.zIndex.appBar,
  padding: theme.spacing(1, 0),
  boxShadow: theme.customShadows.z8,
  top: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

function NavbarHorizontal() {
  const { user } = useAuth();
  const UserRole = user.role;

  return (
    <RootStyle>
      <Container maxWidth={false}>
        <NavSectionHorizontal navConfig={navConfig} />
        <>
          {(() => {
            if (UserRole === 1) {
              return <NavSectionHorizontal navConfig={navConfig} />;
            }
            if (UserRole === 2) {
              return <NavSectionHorizontal navConfig={ProvidernavConfig} />;
            }
            if (UserRole === 3) {
              return <NavSectionHorizontal navConfig={ProvidernavConfig} />;
            }
          })()}
        </>
        {/* {UserRole === 1 ? (
      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />
    ) : (
      <NavSectionVertical navConfig={ProvidernavConfig} isCollapse={isCollapse} />
    )} */}
      </Container>
    </RootStyle>
  );
}

export default memo(NavbarHorizontal);
