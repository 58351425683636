import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { forwardRef } from "react";
// @mui
import { Box } from "@mui/material";

// ----------------------------------------------------------------------
const capitalize = (input) => {
  var CapitalizeWords = input[0]?.toUpperCase();
  for (var i = 1; i <= input.length - 1; i++) {
    let currentCharacter,
      previousCharacter = input[i - 1];
    // eslint-disable-next-line eqeqeq
    if (previousCharacter && previousCharacter == " ") {
      currentCharacter = input[i]?.toUpperCase();
    } else {
      currentCharacter = input[i];
    }
    CapitalizeWords = CapitalizeWords + currentCharacter;
  }
  return CapitalizeWords;
};

const Page = forwardRef(({ children, title = "", meta, ...other }, ref) => (
  <>
    <Helmet>
      <title>{` ${capitalize( title)} | Droupons | Doctor Coupons`}</title>
      {meta}
    </Helmet>

    <Box ref={ref} {...other}>
      {children}
    </Box>
  </>
));

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
