import { Link as RouterLink } from "react-router-dom";
// @mui
import EmailIcon from "@mui/icons-material/Email";

import CallIcon from "@mui/icons-material/Call";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Link,
  Divider,
  Container,
  Typography,
  Stack,
} from "@mui/material";
// routes
import { PATH_PAGE } from "../../routes/paths";
// components
import Logo from "../../components/Logo";
import SocialsButton from "../../components/SocialsButton";
import Iconify from "../../components/Iconify";
// import CloseIcon from "@mui/icons-material/Close";

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: "",
    children: [
      // { name: 'Affordable', href: PATH_PAGE.Affordable },
      // { name: 'Accessible', href: PATH_PAGE.Accessible },
      // { name: 'Connectivity', href: PATH_PAGE.Connectivity },
      // { name: 'HippaCompliant', href: PATH_PAGE.HippaCompliant },
      // { name: "Provider", href: PATH_PAGE.Provider },
      // { name: 'Help & Support', href: '' },
      // { name: "WellnesssStore", href: PATH_PAGE.WellnesssStore },
    ],
  },
  {
    headline: "Information",
    children: [
      { name: "About us", href: PATH_PAGE.about },
      { name: "Contact us", href: PATH_PAGE.contact },
      { name: "FAQs", href: PATH_PAGE.faqs },
      // { name: "Blog", href: PATH_PAGE.blog },
    ],
  },
  {
    headline: "Legal",
    children: [
      { name: "Terms and Condition", href: PATH_PAGE.TermsandCondition },
      { name: "Privacy Policy", href: PATH_PAGE.PrivacyPolicy },
      { name: "Disclaimer", href: PATH_PAGE.Disclaimer },
      { name: "Legal", href: PATH_PAGE.Legal },
    ],
  },
  {
    headline: "Help & Support",
    children: [
      {
        name: " support@droupons.com",
        href: "support@droupons.com",
        icon: <EmailIcon style={{ paddingRight: "5px" }} />,
      },
      {
        name: " Become a Provider",
        href: PATH_PAGE.contact,
        icon: <CallIcon style={{ paddingRight: "5px" }} />,
      },
      // {
      //   name: ' Los Angeles, 359  Hidden Valley Road',
      //   href: '#',
      //   icon: <LocationOnIcon style={{ paddingRight: '5px' }} />,
      // },
    ],
  },
];

const RootStyle = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter(props) {
  return (
    <RootStyle>
      {props.hide ? null : (
        <Divider
          sx={{
            mt: 3,
            boxShadow: ` 0px 2px 4px -1px rgba(145, 158, 171, 0.2),0px 4px 5px 0px rgba(145, 158, 171, 0.14),0px 1px 10px 0px rgba(145, 158, 171, 0.12) !important;`,
          }}
        />
      )}
      {/* close Icon */}
      {/* <CloseIcon fontSize="small" className="CloseIcon" /> */}
      <Container sx={{ pt: 5 }}>
        <Grid
          container
          justifyContent={{ xs: "center", md: "space-between" }}
          sx={{ textAlign: { xs: "center", md: "left" } }}
        >
          <Grid item xs={8} md={3}>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <Logo sx={{ mx: { xs: "auto", md: "inherit" } }} />
            </Grid>

            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              We strive to bring accessible & affordable healthcare for all of
              our patients and convenience to our providers and their staff.
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: "center", md: "flex-start" }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton sx={{ mx: 0.5, color: "#6495ed" }} />
            </Stack>
          </Grid>

          <Grid item xs={12} md={9}>
            <Stack
              spacing={5}
              direction={{ xs: "column", md: "row" }}
              justifyContent="space-between"
            >
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography
                    component="p"
                    variant="overline"
                    sx={{ fontSize: 20, textAlign: "left" }}
                  >
                    {list.headline}
                  </Typography>
                  {list.children.map((link) => (
                    <Link
                      to={link.href}
                      key={link.name}
                      color="inherit"
                      variant="body2"
                      component={RouterLink}
                      sx={{ display: "block" }}
                      className="footer_Icon"
                    >
                      {link.icon} {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Container>

      <Typography
        component="p"
        variant="body2"
        sx={{
          mt: 5,
          pb: 5,
          fontSize: 13,

          textAlign: { xs: "center", md: "center" },
        }}
      >
        © All Rights Reserved. Droupons | Crafted W ith
        <Iconify
          icon="icon-park-outline:love-and-help"
          style={{
            color: "red",
            fontSize: "20px",
            textAlign: "center",
            paddingTop: "6px",
          }}
        />
        by
        <b>
          &nbsp;&nbsp;
          <a
            target="__blank"
            href="https://ityogistech.com/"
            style={{ color: "#6495ed" }}
          >
            ITYogisTech LLC.
          </a>
        </b>
      </Typography>
    </RootStyle>
  );
}
