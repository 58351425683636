// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_ADMIN_DASHBOARD = "/admin";
const ROOTS_PROVIDER_DASHBOARD = "/provider";
const ROOTS_PATIENT_DASHBOARD = "/patient";

// ----------------------------------------------------------------------
// All Page Path

export const PATH_PAGE = {
  Affordable: "/Affordable",
  Accessible: "/Accessible",
  WellnesssStore: "/WellnesssStore",
  about: "/about-us",
  comingSoon: "/coming-soon",
  components: "/components",
  TermsandCondition: "/TermsandCondition",
  PrivacyPolicy: "/PrivacyPolicy",
  Disclaimer: "/Disclaimer",
  Legal: "/Legal",
  Connectivity: "/Connectivity",
  HippaCompliant: "/HippaCompliant",
  contact: "/contact-us",
  blog: "/blog",
  faqs: "/faqs",
  help: "/help",
  Provider: "/FindProvider",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  page404: "/404",
  page500: "/500",
  product: "/WellnesssStore/:name",
  productById: "/WellnesssStore/nike-air-force-1-ndestrukt",
  ProviderProfile: "/ProviderProfile/",
};

// Authentication Path
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  verify: path(ROOTS_AUTH, "/verify"),
  BecomeaProvider: path(ROOTS_AUTH, "/BecomeaProvider"),
};

// All Admin Dashboard Page Path

export const PATH_ADMIN_DASHBOARD = {
  root: ROOTS_ADMIN_DASHBOARD,
  general: {
    dashboard: path(ROOTS_ADMIN_DASHBOARD, "/dashboard"),
    ecommerce: path(ROOTS_ADMIN_DASHBOARD, "/ecommerce"),
    analytics: path(ROOTS_ADMIN_DASHBOARD, "/analytics"),
    banking: path(ROOTS_ADMIN_DASHBOARD, "/banking"),
    appointment: path(ROOTS_ADMIN_DASHBOARD, "/appointment"),
    notification: path(ROOTS_ADMIN_DASHBOARD, "/notification"),
    contact: path(ROOTS_ADMIN_DASHBOARD, "/contact"),
  },
  mail: {
    root: path(ROOTS_ADMIN_DASHBOARD, "/mail"),
    all: path(ROOTS_ADMIN_DASHBOARD, "/mail/all"),
  },
  chat: {
    root: path(ROOTS_ADMIN_DASHBOARD, "/chat"),
    new: path(ROOTS_ADMIN_DASHBOARD, "/chat/new"),
    conversation: path(ROOTS_ADMIN_DASHBOARD, "/chat/:conversationKey"),
  },
  calendar: path(ROOTS_ADMIN_DASHBOARD, "/calendar"),

  provider: {
    root: path(ROOTS_ADMIN_DASHBOARD, "/provider"),
    allprovider: path(ROOTS_ADMIN_DASHBOARD, "/provider/allprovider"),
    providerProfile: path(ROOTS_ADMIN_DASHBOARD, "/provider/profile/"),
    providerCards: path(ROOTS_ADMIN_DASHBOARD, "/provider/providerCards"),
    newProvider: path(ROOTS_ADMIN_DASHBOARD, "/provider/newProvider"),
    editById: path(ROOTS_ADMIN_DASHBOARD, `/provider/:name/edit`),
  },

  user: {
    root: path(ROOTS_ADMIN_DASHBOARD, "/user"),
    list: path(ROOTS_ADMIN_DASHBOARD, "/user/list"),
    newUser: path(ROOTS_ADMIN_DASHBOARD, "/user/new"),
    editById: path(ROOTS_ADMIN_DASHBOARD, `/user/:name/:id/edit`),
  },

  patient: {
    root: path(ROOTS_ADMIN_DASHBOARD, "/patients/"),
    AllPatient: path(ROOTS_ADMIN_DASHBOARD, "/patients/AllPatient"),
    newPatient: path(ROOTS_ADMIN_DASHBOARD, "/patients/newPatient"),
    editPatientById: path(ROOTS_ADMIN_DASHBOARD, `/patients/:name/edit`),
    showPatientsById: path(ROOTS_ADMIN_DASHBOARD, `/patients/:name`),
  },
  eCommerce: {
    root: path(ROOTS_ADMIN_DASHBOARD, "/e-commerce"),
    shop: path(ROOTS_ADMIN_DASHBOARD, "/e-commerce/shop"),
    product: path(ROOTS_ADMIN_DASHBOARD, "/e-commerce/product/:name"),
    productById: path(
      ROOTS_ADMIN_DASHBOARD,
      "/e-commerce/product/nike-air-force-1-ndestrukt"
    ),
    list: path(ROOTS_ADMIN_DASHBOARD, "/e-commerce/list"),
    newProduct: path(ROOTS_ADMIN_DASHBOARD, "/e-commerce/product/new"),
    editById: path(
      ROOTS_ADMIN_DASHBOARD,
      "/e-commerce/product/nike-blazer-low-77-vintage/edit"
    ),
    checkout: path(ROOTS_ADMIN_DASHBOARD, "/e-commerce/checkout"),
    invoice: path(ROOTS_ADMIN_DASHBOARD, "/e-commerce/invoice"),
  },
  blog: {
    root: path(ROOTS_ADMIN_DASHBOARD, "/blog"),
    posts: path(ROOTS_ADMIN_DASHBOARD, "/blog/posts"),
    post: path(ROOTS_ADMIN_DASHBOARD, "/blog/post/:title"),
    postById: path(
      ROOTS_ADMIN_DASHBOARD,
      "/blog/post/apply-these-7-secret-techniques-to-improve-event"
    ),
    newPost: path(ROOTS_ADMIN_DASHBOARD, "/blog/new-post"),
  },
  setting: path(ROOTS_ADMIN_DASHBOARD, "/setting"),
  roles: {
    root: path(ROOTS_ADMIN_DASHBOARD, "/role/"),
    AllRole: path(ROOTS_ADMIN_DASHBOARD, "/role/AllRole"),
    newRole: path(ROOTS_ADMIN_DASHBOARD, "/role/newRole"),
    editRolesById: path(ROOTS_ADMIN_DASHBOARD, `/role/:/:id/edit`),
  },
  services: {
    root: path(ROOTS_ADMIN_DASHBOARD, "/services/"),
    AllServices: path(ROOTS_ADMIN_DASHBOARD, "/services/AllServices"),
    newServices: path(ROOTS_ADMIN_DASHBOARD, "/services/newServices"),
    editServicesById: path(ROOTS_ADMIN_DASHBOARD, `/services/:/:id/edit`),
  },
  designation: {
    root: path(ROOTS_ADMIN_DASHBOARD, "/designation/"),
    AllDesignation: path(ROOTS_ADMIN_DASHBOARD, "/designation/AllDesignation"),
    newDesignation: path(ROOTS_ADMIN_DASHBOARD, "/designation/newDesignation"),
    editDesignationById: path(ROOTS_ADMIN_DASHBOARD, `/designation/:/:id/edit`),
  },
  allergies: {
    root: path(ROOTS_ADMIN_DASHBOARD, "/allergies/"),
    AllAllergies: path(ROOTS_ADMIN_DASHBOARD, "/allergies/AllAllergies"),
    newAllergies: path(ROOTS_ADMIN_DASHBOARD, "/allergies/newAllergies"),
    editAllergiesById: path(ROOTS_ADMIN_DASHBOARD, `/allergies/:/:id/edit`),
  },
  symptoms: {
    root: path(ROOTS_ADMIN_DASHBOARD, "/symptoms/"),
    AllSymptoms: path(ROOTS_ADMIN_DASHBOARD, "/symptoms/AllSymptoms"),
    newSymptoms: path(ROOTS_ADMIN_DASHBOARD, "/symptoms/newSymptoms"),
    editSymptomsById: path(ROOTS_ADMIN_DASHBOARD, `/symptoms/:name/:id/edit`),
  },
  providertype: {
    root: path(ROOTS_ADMIN_DASHBOARD, "/providerType/"),
    AllProviderType: path(
      ROOTS_ADMIN_DASHBOARD,
      "/providerType/AllProviderType"
    ),
    newProviderType: path(
      ROOTS_ADMIN_DASHBOARD,
      "/providerType/newProviderType"
    ),
    editProviderTypeById: path(ROOTS_ADMIN_DASHBOARD, `/providerType/:id/edit`),
  },
  SBC: {
    root: path(ROOTS_ADMIN_DASHBOARD, "/SBC/"),
    AllSBC: path(ROOTS_ADMIN_DASHBOARD, "/SBC/AllSBC"),
    newSBC: path(ROOTS_ADMIN_DASHBOARD, "/SBC/newSBC"),
    editSBCById: path(ROOTS_ADMIN_DASHBOARD, `/SBC/:id/edit`),
  },
  SUBSBC: {
    root: path(ROOTS_ADMIN_DASHBOARD, "/SUBSBC/"),
    AllSUBSBC: path(ROOTS_ADMIN_DASHBOARD, "/SUBSBC/AllSUBSBC"),
    newSUBSBC: path(ROOTS_ADMIN_DASHBOARD, "/SUBSBC/newSUBSBC"),
    editSUBSBCById: path(ROOTS_ADMIN_DASHBOARD, `/SUBSBC/:id/edit`),
  },
};

// Provider Dashboard Page Path
export const PATH_PROVIDER_DASHBOARD = {
  root: ROOTS_PROVIDER_DASHBOARD,
  general: {
    dashboard: path(ROOTS_PROVIDER_DASHBOARD, "/dashboard"),
    analytics: path(ROOTS_PROVIDER_DASHBOARD, "/analytics"),
    wallet: path(ROOTS_PROVIDER_DASHBOARD, "/wallet"),
    booking: path(ROOTS_PROVIDER_DASHBOARD, "/booking"),
    notification: path(ROOTS_PROVIDER_DASHBOARD, "/notification"),
    paymentandbillings: path(ROOTS_PROVIDER_DASHBOARD, "/payment&billings"),
    patients: path(ROOTS_PROVIDER_DASHBOARD, "/patients"),
    showPatientsById: path(ROOTS_PROVIDER_DASHBOARD, `/patient/:name`),
    Patient_Experience: path(ROOTS_PROVIDER_DASHBOARD, `/PatientExperience`),
  },
  mail: {
    root: path(ROOTS_PROVIDER_DASHBOARD, "/mail"),
    all: path(ROOTS_PROVIDER_DASHBOARD, "/mail/all"),
  },
  chat: {
    root: path(ROOTS_PROVIDER_DASHBOARD, "/chat"),
    new: path(ROOTS_PROVIDER_DASHBOARD, "/chat/new"),
    conversation: path(ROOTS_PROVIDER_DASHBOARD, "/chat/:conversationKey"),
  },
  calendar: path(ROOTS_PROVIDER_DASHBOARD, "/calendar"),
  providerProfile: path(ROOTS_PROVIDER_DASHBOARD, "/OtherProvider/profile/"),
  user: {
    root: path(ROOTS_PROVIDER_DASHBOARD, "/user"),
    profile: path(ROOTS_PROVIDER_DASHBOARD, "/user/profile"),
    account: path(ROOTS_PROVIDER_DASHBOARD, "/user/account"),
  },
  setting: path(ROOTS_PROVIDER_DASHBOARD, "/setting"),
};

// PATIENT Dashboard Page Path
export const PATH_PATIENT_DASHBOARD = {
  dashboard: path(ROOTS_PATIENT_DASHBOARD, "/dashboard"),
  user: {
    root: path(ROOTS_PATIENT_DASHBOARD, "/user"),
    profile: path(ROOTS_PATIENT_DASHBOARD, "/user/profile"),
    cards: path(ROOTS_PATIENT_DASHBOARD, "/user/provider"),
    list: path(ROOTS_PATIENT_DASHBOARD, "/user/list"),

    account: path(ROOTS_PATIENT_DASHBOARD, "/user/account"),
  },
  providerProfile: path(ROOTS_PATIENT_DASHBOARD, "/user/provider/profile/"),
  general: {
    appointment: path(ROOTS_PATIENT_DASHBOARD, "/appointment"),
    Rewards: path(ROOTS_PATIENT_DASHBOARD, "/rewards"),
    orders: path(ROOTS_PATIENT_DASHBOARD, "/orders"),
    notification: path(ROOTS_PATIENT_DASHBOARD, "/notification"),
    paymentandbillings: path(ROOTS_PATIENT_DASHBOARD, "/payment&billings"),
  },
  mail: {
    root: path(ROOTS_PATIENT_DASHBOARD, "/mail"),
    all: path(ROOTS_PATIENT_DASHBOARD, "/mail/all"),
  },
  chat: {
    root: path(ROOTS_PATIENT_DASHBOARD, "/chat"),
    new: path(ROOTS_PATIENT_DASHBOARD, "/chat/new"),
    conversation: path(ROOTS_PATIENT_DASHBOARD, "/chat/:conversationKey"),
  },
  calendar: path(ROOTS_PATIENT_DASHBOARD, "/calendar"),
  setting: path(ROOTS_PATIENT_DASHBOARD, "/setting"),
};
