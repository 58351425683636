import * as React from "react";

import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";

import Iconify from "../../components/Iconify";
import { useEffect } from "react";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import CloseIcon from "@mui/icons-material/Close";
import errorImage from "../../assets/Image/MaleDoctor.png";
import useIsMountedRef from "../../hooks/useIsMountedRef";
import axiosInstance from "../../utils/axios";
import { Tab } from "@mui/material";
export default function FilterBar() {
  const navigate = useNavigate();
  const [ProviderTypes, setProviderTypes] = React.useState([]);

  const isMountedRef = useIsMountedRef();
  const getProviderTypes = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/provider_types");

      if (isMountedRef.current) {
        if (response.data.Status === false) {
          return [];
        } else {
          setProviderTypes(response.data);
        }
      }
    } catch (err) {
      //
    }
  }, [isMountedRef]);

  useEffect(() => {
    getProviderTypes();
  }, [getProviderTypes]);

  const go = (url, type) => {
    navigate(`/?id=${url}&type=${type}`);
  };

  const [state, setState] = React.useState({
    // top: false,
    // left: false,
    // bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            onClick={toggleDrawer(anchor, true)}
            variant="outlined"
            startIcon={<Iconify icon={"cil:list-filter"} />}
            sx={{ color: "black", border: "1px solid #d9d0d0" }}
          >
            filter
          </Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            <CloseIcon
              fontSize="small"
              className="CloseIcon"
              onClick={toggleDrawer(anchor, false)}
            />
            <Tab
              // style={{ display: 'none' }}
              icon={
                <>
                  <img
                    alt={"All"}
                    src={`${
                      process.env.REACT_APP_HOST_API_URL
                    }/uploads/ProviderTypeIcon/${"All"}`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = errorImage;
                    }}
                    width="25px"
                    height="25px"
                  />
                </>
              }
              label={"All"}
              key={"All"}
              onClick={() => {
                navigate(`/`);
              }}
              sx={{ fontWeight: "normal", display: "none" }}
            />

            {ProviderTypes?.map((data, i) => (
              <Tab
                icon={
                  <>
                    <img
                      alt={data.PROVIDER_TYPE_ICON}
                      src={`${process.env.REACT_APP_HOST_API_URL}/uploads/ProviderTypeIcon/${data.PROVIDER_TYPE_ICON}`}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = errorImage;
                      }}
                      width="30px"
                      height="30px"
                    />
                  </>
                }
                label={data.PROVIDER_TYPE_NAME}
                key={data.PROVIDER_TYPE_NAME}
                onClick={() => {
                  go(data.PROVIDER_TYPE_ID, data.PROVIDER_TYPE_NAME);
                }}
                sx={{ fontWeight: "normal" }}
                style={{ color: "grey" }}
              />
            ))}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
