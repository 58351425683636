/* eslint-disable no-unused-vars */
import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";

// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";

import LoadingScreen from "../components/LoadingScreen";
import RoleBasedGuard from "../guards/RoleBasedGuard";
// config
import { PATH_AFTER_LOGIN, PATIENT_PATH_AFTER_LOGIN, PROVIDER_PATH_AFTER_LOGIN} from "../config";
import ProviderAppointmentDataTable from "../sections/@dashboard/user/Provider/Appoinments/ProviderAppoinmentDataTable";
import AdminAppointment from "../sections/@dashboard/user/Admin/AdminAppointment";
// components

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // All Routes For Authentication
    {
      path: "auth",
      element: <MainLayout />,
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: "reset-password",
          element: <ResetPassword />,
        },
        {
          path: "verify",
          element: <VerifyCode />,
        },
        {
          path: "verified",
          element: <Verified />,
        },
        { path: "BecomeaProvider", element: <BecomeaProvider /> },
      ],
    },

    // Admin Dashboard Routes
    {
      path: "admin",
      element: (
        <AuthGuard>
          <RoleBasedGuard accessibleRoles={[1]}>
            <DashboardLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={PATH_AFTER_LOGIN} replace />,
          index: true,
        },
        {
          path: "dashboard",
          element: <GeneralApp />,
        },
        {
          path: "notification",
          element: <PatientNotificationSetting />,
        },
        {
          path: "ecommerce",
          element: <GeneralEcommerce />,
        },

        {
          path: "banking",
          element: <GeneralBanking />,
        },
        {
          path: "appointment",
          element: <AdminAppointment />,
        },

        {
          path: "e-commerce",
          children: [
            {
              element: <Navigate to="/admin/e-commerce/" replace />,
              index: true,
            },

            { path: "list", element: <EcommerceProductList /> },
            { path: "product/new", element: <EcommerceProductCreate /> },
            { path: "product/:name/edit", element: <EcommerceProductCreate /> },
          ],
        },
        {
          path: "user",
          children: [
            {
              element: <Navigate to="/admin/user/list" replace />,
              index: true,
            },
            { path: "list", element: <UserList /> },
            { path: "new", element: <RegisterNewUser /> },
            { path: ":name/:id/edit", element: <UserNewAndEditForm /> },
          ],
        },

        {
          path: "provider",
          children: [
            {
              element: <Navigate to="/admin/provider/allprovider" replace />,
              index: true,
            },
            { path: "allprovider", element: <AdminAllProvider /> },
            {
              path: "profile/:id/:user_name",
              element: <ProviderSingleProfile />,
            },
            { path: ":name/edit", element: <UserCreate /> },
          ],
        },

        {
          path: "patients",
          children: [
            {
              element: <Navigate to="/admin/patients/" replace />,
              index: true,
            },
            { path: "AllPatient", element: <AdminAllPatient /> },
            { path: "profile/:username", element: <PatientSingleProfile /> },
            { path: "newPatient", element: <UserCreate /> },
            { path: ":name/edit", element: <UserCreate /> },
          ],
        },
        {
          path: "blog",
          children: [
            {
              element: <Navigate to="/admin/blog/posts" replace />,
              index: true,
            },
            { path: "posts", element: <BlogPosts /> },
            { path: "post/:title", element: <BlogPost /> },
            { path: "new-post", element: <BlogNewPost /> },
          ],
        },
        {
          path: "mail",
          children: [
            { element: <Navigate to="/admin/mail/all" replace />, index: true },
            { path: "label/:customLabel", element: <Mail /> },
            { path: "label/:customLabel/:mailId", element: <Mail /> },
            { path: ":systemLabel", element: <Mail /> },
            { path: ":systemLabel/:mailId", element: <Mail /> },
          ],
        },
        {
          path: "chat",
          children: [
            { element: <Chat />, index: true },
            { path: "new", element: <Chat /> },
            { path: ":conversationKey", element: <Chat /> },
          ],
        },
        {
          path: "calendar",
          element: <Calendar />,
        },
        { path: "setting", element: <UserAccountSetting /> },
        { path: "contact", element: <ShowAllContact /> },

        {
          path: "role",
          children: [
            { element: <Navigate to="/admin/role/" replace />, index: true },
            { path: "AllRole", element: <Role /> },
            { path: "newRole", element: <NewRoleCreate /> },
            { path: ":RoleName/:id/edit", element: <NewRoleCreate /> },
          ],
        },
        {
          path: "services",
          children: [
            {
              element: <Navigate to="/admin/services/AllServices" replace />,
              index: true,
            },
            { path: "AllServices", element: <Services /> },
            { path: "newServices", element: <NewServicesCreate /> },
            {
              path: ":ServicesName/:id/:charges/edit",
              element: <NewServicesCreate />,
            },
          ],
        },
        {
          path: "designation",
          children: [
            {
              element: <Navigate to="/admin/designation/" replace />,
              index: true,
            },
            { path: "AllDesignation", element: <Designation /> },
            { path: "newDesignation", element: <NewDesignationCreate /> },
            {
              path: ":DesignationName/:id/edit",
              element: <NewDesignationCreate />,
            },
          ],
        },

        {
          path: "allergies",
          children: [
            {
              element: <Navigate to="/admin/allergies/AllAllergies" replace />,
              index: true,
            },
            { path: "AllAllergies", element: <Allergies /> },
            { path: "newAllergies", element: <NewAllergiesCreate /> },
            //            { path: ':AllergiesName/:id/edit', element: <NewAllergiesCreate /> },
            { path: ":id/edit", element: <NewAllergiesCreate /> },
          ],
        },

        {
          path: "Symptoms",
          children: [
            {
              element: <Navigate to="/admin/symptoms/AllSymptoms" replace />,
              index: true,
            },
            { path: "AllSymptoms", element: <Symptoms /> },
            { path: "newSymptoms", element: <NewSymptomsCreate /> },
            { path: ":SymptomsName/:id/edit", element: <NewSymptomsCreate /> },
          ],
        },
        {
          path: "providerType",
          children: [
            {
              element: <Navigate to="/admin/providerType/" replace />,
              index: true,
            },
            { path: "AllProviderType", element: <ProviderTypes /> },
            { path: "newProviderType", element: <NewProviderTypesCreate /> },
            { path: ":id/edit", element: <NewProviderTypesCreate /> },
          ],
        },
        {
          path: "SBC",
          children: [
            { element: <Navigate to="/admin/SBC/" replace />, index: true },
            { path: "AllSBC", element: <SBC /> },
            { path: "newSBC", element: <NewSBCCreate /> },
            { path: ":id/edit", element: <NewSBCCreate /> },
          ],
        },
        {
          path: "SUBSBC",
          children: [
            { element: <Navigate to="/admin/SUBSBC/" replace />, index: true },
            { path: "AllSUBSBC", element: <SUBSBC /> },
            { path: "newSUBSBC", element: <NewSUBSBCCreate /> },
            { path: ":id/edit", element: <NewSUBSBCCreate /> },
          ],
        },
      ],
    },

    // Provider  Routes
    {
      path: "provider",
      element: (
        <AuthGuard>
          <RoleBasedGuard accessibleRoles={[2]}>
            <DashboardLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        {
          element: (
            <Navigate to={PROVIDER_PATH_AFTER_LOGIN.dashboard} replace />
          ),
          index: true,
        },
        {
          path: "dashboard",
          element: <GeneralApp />,
        },

        {
          path: "Wallet",
          // element: <GeneralBanking />,
          element: <Maintenance />,
        },
        {
          path: "booking",
          //  element: <GeneralBooking />,
          element: <ProviderAppointmentDataTable />,
        },
        {
          path: "payment&billings",
          element: <PatientPaymentBillings />,
        },
        {
          path: "notification",
          element: <PatientNotificationSetting />,
        },
        { path: "patients", element: <PatientListforProvider /> },
        { path: "PatientExperience", element: <PatientExperience /> },
        { path: "patients/:name", element: <PatientSingleProfile /> },
        { path: "edit_service", element: <ProviderServiceUpdateForm /> },
        {
          path: "user",
          children: [
            {
              element: <Navigate to="/provider/user/profile" replace />,
              index: true,
            },
            { path: "profile", element: <UserProfile /> },
          ],
        },
        {
          path: "OtherProvider/profile/:id/:user_name",
          element: <ProviderSingleProfile />,
        },

        {
          path: "setting",
          element: <UserAccountSetting />,
        },
        {
          path: "mail",
          children: [
            {
              element: <Navigate to="/provider/mail/all" replace />,
              index: true,
            },
            { path: "label/:customLabel", element: <Mail /> },
            { path: "label/:customLabel/:mailId", element: <Mail /> },
            { path: ":systemLabel", element: <Mail /> },
            { path: ":systemLabel/:mailId", element: <Mail /> },
          ],
        },
        {
          path: "chat",
          children: [
            { element: <Chat />, index: true },
            { path: "new", element: <Chat /> },

            { path: ":conversationKey", element: <Chat /> },
          ],
        },
        {
          path: "calendar",
          element: <Calendar />,
        },
      ],
    },

    // Patient  Routes
    {
      path: "patient",
      element: (
        <AuthGuard>
          <RoleBasedGuard accessibleRoles={[3]}>
            <DashboardLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={PATIENT_PATH_AFTER_LOGIN.dashboard} replace />,
          index: true,
        },
        {
          path: "dashboard",
          element: <GeneralApp />,
        },
        {
          path: "orders",
          element: <Maintenance />,
        },
        {
          path: "Rewards",
          //  element: <GeneralBooking />,
          element: <Maintenance />,
        },
        {
          path: "appointment",
          element: <PatientAppointment />,
        },
        {
          path: "payment&billings",
          element: <PatientPaymentBillings />,
        },
        {
          path: "notification",
          element: <PatientNotificationSetting />,
        },

        { path: "setting", element: <UserAccountSetting /> },
        {
          path: "user",
          children: [
            {
              element: <Navigate to="/patient/user/profile" replace />,
              index: true,
            },
            { path: "profile", element: <ShowProfile /> },
            { path: "provider", element: <MyProvider /> },
            {
              path: "provider/profile/:id/:user_name",
              element: <ProviderSingleProfile />,
            },
            { path: "list", element: <UserList /> },
            { path: ":name/edit", element: <UserCreate /> },
          ],
        },

        {
          path: "mail",
          children: [
            {
              element: <Navigate to="/patient/mail/all" replace />,
              index: true,
            },
            { path: "label/:customLabel", element: <Mail /> },
            { path: "label/:customLabel/:mailId", element: <Mail /> },
            { path: ":systemLabel", element: <Mail /> },
            { path: ":systemLabel/:mailId", element: <Mail /> },
          ],
        },
        {
          path: "chat",
          children: [
            { element: <Chat />, index: true },
            { path: "new", element: <Chat /> },
            { path: ":conversationKey", element: <Chat /> },
          ],
        },
        {
          path: "calendar",
          element: <Calendar />,
        },
      ],
    },

    // All Statistic Pages Routes for Front end page
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: "about-us", element: <About /> },
        { path: "contact-us", element: <Contact /> },
        { path: "faqs", element: <Faqs /> },
        { path: "Affordable", element: <Affordable /> },
        { path: "Accessible", element: <Accessible /> },
        { path: "Connectivity", element: <Connectivity /> },
        { path: "HippaCompliant", element: <HippaCompliant /> },
        { path: "FindProvider", element: <Provider /> },
        { path: "TermsandCondition", element: <TermsandCondition /> },
        { path: "PrivacyPolicy", element: <PrivacyPolicy /> },
        { path: "Disclaimer", element: <Disclaimer /> },
        { path: "Legal", element: <Legal /> },
        { path: "help", element: <Legal /> },

        { path: "maintenance", element: <Maintenance /> },
    
        { path: "payment", element: <Payment /> },
        { path: "WellnesssStore", element: <WellnesssStore /> },
        { path: "shop", element: <WellnesssStore /> },
        { path: "blog", element: <BlogPosts /> },
        { path: "blog/:title", element: <BlogPost /> },
        { path: "WellnesssStore/:name", element: <EcommerceProductDetails /> },
        { path: "WellnesssStore/checkout", element: <EcommerceCheckout /> },
        { path: "WellnesssStore/invoice", element: <EcommerceInvoice /> },
        {
          path: "/ProviderProfile/:id/:user_name",
          element: <ProviderSingleProfile />,
        },
        { path: "/:type", element: <HomePage /> },
      ],
    },
    // Error Page Routes
    {
      path: "*",
      children: [
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const ResetPassword = Loadable(lazy(() => import("../pages/auth/ResetPassword")));
const VerifyCode = Loadable(lazy(() => import("../pages/auth/VerifyCode")));
// Dashboard
const GeneralApp = Loadable(lazy(() => import("../pages/dashboard/GeneralApp")));
const GeneralEcommerce = Loadable(lazy(() => import("../pages/dashboard/GeneralEcommerce")));

const GeneralBanking = Loadable(lazy(() => import("../pages/dashboard/GeneralBanking")));
const GeneralBooking = Loadable(lazy(() => import("../pages/dashboard/GeneralBooking")));

const EcommerceProductDetails = Loadable(lazy(() => import("../pages/dashboard/EcommerceProductDetails")));
const EcommerceProductList = Loadable(lazy(() => import("../pages/dashboard/EcommerceProductList")));
const EcommerceProductCreate = Loadable(lazy(() => import("../pages/dashboard/EcommerceProductCreate")));
const EcommerceCheckout = Loadable(lazy(() => import("../pages/dashboard/EcommerceCheckout")));
const EcommerceInvoice = Loadable(lazy(() => import("../pages/dashboard/EcommerceInvoice")));
const BlogPosts = Loadable(lazy(() => import("../pages/BlogPosts")));
const BlogPost = Loadable(lazy(() => import("../pages/BlogPost")));
const BlogNewPost = Loadable(lazy(() => import("../pages/dashboard/BlogNewPost")));
const UserProfile = Loadable(lazy(() => import("../sections/@dashboard/user/Provider/ProvoderProfile")));
const MyProvider = Loadable(lazy(() => import("../sections/@dashboard/user/Patient/MyProvider")));
const UserList = Loadable(lazy(() => import("../pages/dashboard/UserList")));
const UserAccountSetting = Loadable(lazy(() => import("../pages/dashboard/UserAccountSetting")));
const UserCreate = Loadable(lazy(() => import("../pages/dashboard/UserCreate")));
const Chat = Loadable(lazy(() => import("../pages/dashboard/Chat")));
const Mail = Loadable(lazy(() => import("../pages/dashboard/Mail")));
const Calendar = Loadable(lazy(() => import("../pages/dashboard/Calendar")));
const Allergies = Loadable(lazy(() => import("../pages/dashboard/Allergies/Allergies")));
const NewAllergiesCreate = Loadable(lazy(() => import("../pages/dashboard/Allergies/NewAllergies")));
const NewSymptomsCreate = Loadable(lazy(() => import("../pages/dashboard/Symptoms/NewSymptoms")));
const ProviderTypes = Loadable(lazy(() => import("../pages/dashboard/ProviderTypes/ProviderTypes")));
const NewProviderTypesCreate = Loadable(lazy(() => import("../pages/dashboard/ProviderTypes/NewProviderTypes")));

const SBC = Loadable(lazy(() => import("../pages/dashboard/SBC/SBC")));
const NewSBCCreate = Loadable(lazy(() => import("../pages/dashboard/SBC/NewSBC")));
const SUBSBC = Loadable(lazy(() => import("../pages/dashboard/SUBSBC/SUBSBC")));
const NewSUBSBCCreate = Loadable(lazy(() => import("../pages/dashboard/SUBSBC/NewSUBSBC")));

const Role = Loadable(lazy(() => import("../pages/dashboard/Role/Role")));
const NewRoleCreate = Loadable(lazy(() => import("../pages/dashboard/Role/NewRole")));
const Services = Loadable(lazy(() => import("../pages/dashboard/Services/Services")));
const NewServicesCreate = Loadable(lazy(() => import("../pages/dashboard/Services/NewServices")));
const NewDesignationCreate = Loadable(lazy(() => import("../pages/dashboard/designation/NewDesignation")));
const Designation = Loadable(lazy(() => import("../pages/dashboard/designation/Designation")));
const UserNewAndEditForm = Loadable(lazy(() => import("../sections/@dashboard/user/UserNewAndEditForm")));

const Verified = Loadable(lazy(() => import("../pages/auth/Verified")));
const PatientNotificationSetting = Loadable(lazy(() => import("../sections/@dashboard/user/Patient/PatientNotificationSetting")));

const PatientPaymentBillings = Loadable(lazy(() => import("../sections/@dashboard/user/Patient/PatientPaymentBillings")));
const ShowProfile = Loadable(lazy(() => import("../sections/@dashboard/user/Patient/PatientSelfProfile")));
const PatientAppointment = Loadable(lazy(() => import("../sections/@dashboard/user/Patient/PatientAppointment")));
const PatientListforProvider = Loadable(lazy(() => import("../pages/dashboard/PatientListforProvider")));
const PatientExperience = Loadable(lazy(() => import("../sections/@dashboard/user/Provider/PatientExperience")));
const PatientSingleProfile = Loadable(lazy(() => import("../sections/@dashboard/user/Patient/PatientSingleProfile")));
const ProviderAppointment = Loadable(lazy(() => import("../sections/@dashboard/user/Provider/Appoinments/ProviderAppoinment")));

const AdminAllPatient = Loadable(lazy(() => import("../pages/dashboard/AdminAllPatient")));
const AdminAllProvider = Loadable(lazy(() => import("../pages/dashboard/AdminAllProvider")));
const ProviderSingleProfile = Loadable(lazy(() => import("../sections/@dashboard/user/Provider/ProviderSingleProfile")));
const Symptoms = Loadable(lazy(() => import("../pages/dashboard/Symptoms/Symptoms")));
const BecomeaProvider = Loadable(lazy(() => import("../sections/home/BecomeaProvider")));
const RegisterNewUser = Loadable(lazy(() => import("../sections/@dashboard/user/Admin/RegisterNewUser")));
const ShowAllContact = Loadable(lazy(() => import("../sections/@dashboard/user/Admin/ShowAllContact")));
const ProviderServiceUpdateForm = Loadable(lazy(() => import("../sections/@dashboard/user/Provider/Service/ProviderServiceUpdateForm")));

// Main
const HomePage = Loadable(lazy(() => import("../pages/Home")));
const WellnesssStore = Loadable(lazy(() => import("../pages/WellnessStore/WellnessStore")));
const About = Loadable(lazy(() => import("../pages/About")));
const Provider = Loadable(lazy(() => import("../pages/ProviderPage/Provider")));
const Contact = Loadable(lazy(() => import("../pages/Contact")));
const Faqs = Loadable(lazy(() => import("../pages/Faqs")));
const Affordable = Loadable(lazy(() => import("../pages/Affordable")));
const Accessible = Loadable(lazy(() => import("../pages/Accessible")));
const Connectivity = Loadable(lazy(() => import("../pages/Connectivity")));
const HippaCompliant = Loadable(lazy(() => import("../pages/HippaCompliant")));
const TermsandCondition = Loadable(lazy(() => import("../pages/TermsandCondition")));
const PrivacyPolicy = Loadable(lazy(() => import("../pages/PrivacyPolicy")));
const Disclaimer = Loadable(lazy(() => import("../pages/Disclaimer")));
const Legal = Loadable(lazy(() => import("../pages/Legal")));
const ComingSoon = Loadable(lazy(() => import("../pages/ComingSoon")));
const Maintenance = Loadable(lazy(() => import("../pages/Maintenance")));

const Payment = Loadable(lazy(() => import("../pages/Payment")));
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
