/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

// utils
import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";
import socket from "../utils/socket";
import { sendNotification } from "../push-notifications";

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return { ...state, isAuthenticated, isInitialized: true, user };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  MyAuthProvider: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [userEmail, setUserEmail] = useState(
    window.localStorage.getItem("user_email")
  );
  const [user_type, setUserType] = useState(
    window.localStorage.getItem("user_type")
  );
  // var token = window.localStorage.getItem('accessToken');
  // var decoded = jwt_decode(token);
  // console.log(decoded,"decodeddecodeddecodeddecoded");

  // for remove error
  setTimeout(() => {
    sessionStorage.removeItem("error");
  }, 500);

  const MyAuthProvider = (user_type, userEmail) => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        setUserEmail(window.localStorage.getItem("user_email"));
        setUserType(window.localStorage.getItem("user_type"));
        const data = {
          params: {
            email: userEmail,
            role: user_type,
          },
        };

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get("/account/my-account", data);
          const { user } = response.data;

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        setUserEmail(window.localStorage.getItem("user_email"));
        setUserType(window.localStorage.getItem("user_type"));
        const data = {
          params: {
            email: userEmail,
            role: user_type,
          },
        };

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get("/account/my-account", data);
          const { user } = response.data;

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post("/account/login", {
      email,
      password,
    });
    sessionStorage.removeItem("error");
    if (response.data.email_verified === false) {
      // Swal.fire({
      //   title: 'Warning',
      //   text: response.data.Message,
      //   icon: 'warning',
      //   confirmButtonColor: '#3085d6',
      //   confirmButtonText: 'Ok',
      //   timer: 1000,
      // });
      localStorage.setItem("user_email", email);
      window.location.replace(`/auth/verify`, { email });
    }
    if (response.data.Status === true) {
      const { accessToken, user } = response.data;

      setSession(accessToken);

      localStorage.setItem("user_email", user.email);
      localStorage.setItem("user_type", user.role);

      dispatch({
        type: "LOGIN",
        payload: {
          user,
        },
      });
      MyAuthProvider(user.role, user.email);
    }
    if (response.data.Status === false) {
      const error = response.data.Message;
      sessionStorage.setItem("error", error);
    }
  };

  const register = async (
    role,
    provider_types,
    email,
    password,
    firstName,
    lastName,
    created_by
  ) => {
    const response = await axios.post("/account/register", {
      role,
      provider_types,
      email,
      password,
      firstName,
      lastName,
      created_by,
    });
    const { accessToken, user } = response.data;

    if (response.data.Status === false) {
      // Swal.fire({
      //   title: 'Warning',
      //   text: response.data.Message,
      //   icon: 'warning',
      //   confirmButtonColor: '#3085d6',
      //   confirmButtonText: 'Ok',
      //   timer: 1000,
      // });

      const error = response.data.Message;
      sessionStorage.setItem("error", error);
    } else {
      if (created_by === "ADMIN") {
        Swal.fire({
          title: "Success",
          text: response.data.Message,
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
          timer: 1000,
        });
        window.history.back();
      } else {
        localStorage.setItem("user_email", email);
        // window.location.replace(`/auth/verify?email=${email}`);
        // window.location.replace(`/auth/verify`);
        if (response.data.Status === true) {
          const { accessToken, user } = response.data;

          setSession(accessToken);

          localStorage.setItem("user_email", user.email);
          localStorage.setItem("user_type", user.role);

          dispatch({
            type: "LOGIN",
            payload: {
              user,
            },
          });
          MyAuthProvider(user.role, user.email);
          let body = {
            user_role: user?.role,
            type: "Register",
            sender_user_id: user?.id,
            title: `New User ${user?.displayName} Register`,
            description: `New User ${user?.displayName} Register Successfully `,
            receiver_user_id: 1,
            notification_type: "Register",
            date_time: new Date(),
          };
          console.log(body,"notification body");
          sendNotification(body);
          socket.emit("sendNotification", body);
        }
        window.localStorage.setItem("accessToken", accessToken);
        dispatch({
          type: "REGISTER",
          payload: {
            user,
          },
        });
      }
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
    localStorage.setItem("user_email", "null");
    localStorage.clear();
    sessionStorage.clear();
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        register,
        MyAuthProvider,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
