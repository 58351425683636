import * as React from "react";
import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";

import Divider from "@mui/material/Divider";

import MainFooter from "./MainFooter";
import Iconify from "../../components/Iconify";
import { Link } from "react-router-dom";
import { PATH_PAGE } from "../../routes/paths";
import CloseIcon from "@mui/icons-material/Close";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.background.default,
}));

const style = {
  backgroundColor: "#F8F8F8",
  borderTop: "1px solid #E7E7E7",
  padding: "20px",
  position: "fixed",
  left: "0",
  bottom: "0",
  maxHeight: "90px",
  width: "100%",
  display: "flex",
  zIndex: 9999,
  justifyContent: "space-between",
};
// ----------------------------------------------------------------------

export default function HomeFooter() {

  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <CloseIcon fontSize="small" className="CloseIcon" />
      <MainFooter hide={true} />
    </Box>
  );
  return (
    <RootStyle>
      <Divider
        sx={{
          mt: 3,
          boxShadow: ` 0px 2px 4px -1px rgba(145, 158, 171, 0.2),0px 4px 5px 0px rgba(145, 158, 171, 0.14),0px 1px 10px 0px rgba(145, 158, 171, 0.12) !important;`,
        }}
      />
      <div style={style} className='HomeFooter'>
        <div style={{ display: "flex" }}>
          <span style={{ margin: " 0px 50px", listStyleType: "none", fontSize: "14px", lineHeight: '18px', }}>
            © 2022 All Rights Reserved. Droupons | Crafted W ith by
            <a target="__blank" href="https://ityogistech.com/" style={{ color: "#6495ed", textDecoration: "none" }}> ITYogisTech LLC. </a>
          </span>

          <li style={{ margin: " 0px -31px", color: "#000", fontSize: "14px" }}>
            <Link to={PATH_PAGE.PrivacyPolicy} style={{ textDecoration: "none" }}> Privacy </Link> </li>
          <li style={{ margin: " 0px 50px", color: "#000", fontSize: "14px" }} >
            <Link to={PATH_PAGE.TermsandCondition} style={{ textDecoration: "none" }} >
              Terms
            </Link>
          </li>
          <li style={{ margin: " 0px -31px", color: "#000", fontSize: "14px" }}>
            <Link to={PATH_PAGE.PrivacyPolicy} style={{ textDecoration: "none" }}>
              Sitemap
            </Link>
          </li>
        </div>
        <div style={{ display: "flex", listStyleType: "none", margin: " -8px 43px" }} className='footer_arrow'>
          {/* <li style={{margin:" 0px 10px" ,display:"flex"}}>
            <LanguagePopover />
            English(US)
          </li> */}

          {["bottom"].map((anchor) => (
            <React.Fragment key={anchor}>
              <Button
                onClick={toggleDrawer(anchor, true)}
                sx={{ color: "#000", fontSize: "14px" }}
              >
                Support & Resource
                <Iconify icon={"ep:arrow-up"} width={20} height={20} />
              </Button>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
              >
                {list(anchor)}
              </Drawer>
            </React.Fragment>
          ))}

        </div>
      </div>
    </RootStyle>
  );
}
