import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import useIsMountedRef from "../../hooks/useIsMountedRef";
import { useCallback } from "react";
import axios from "../../utils/axios";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import errorImage from "../../assets/Image/MaleDoctor.png";
export default function ProviderTypeMenu() {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const [value, setValue] = React.useState(parseInt(id) || 0);

  const navigate = useNavigate();
  const [ProviderTypes, setProviderTypes] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isMountedRef = useIsMountedRef();
  const getProviderTypes = useCallback(async () => {
    try {
      const response = await axios.get("/provider_types");

      if (isMountedRef.current) {
        if (response.data.Status === false) {
          return []
        }
        else {
          setProviderTypes(response.data);
        }
      }
    } catch (err) {
      //
    }
  }, [isMountedRef]);

  useEffect(() => {
    getProviderTypes();
  }, [getProviderTypes]);

  const go = (url, type) => {
    navigate(`/?id=${url}&type=${type}`);
  };

  return (
    <Box sx={{ maxWidth: "90%", bgcolor: "background.paper" ,paddingRight:'5px'}} className='scroll_menu_tab'>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="icon position tabs example"
        className="menu_button"
      >
        <Tab
          // style={{ display: 'none' }}
          icon={
            <>
              <img
                alt={"All"}
                src={`${process.env.REACT_APP_HOST_API_URL}/uploads/ProviderTypeIcon/all.png`}
                style={{ mr: 2, borderRadius: "100%" }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = errorImage;
                }}
                width="25px"
                height="25px"
              />
            </>
          }
          label={"All"}
          key={"All"}
          onClick={() => {
            navigate(`/`);
          }}
          sx={{ fontWeight: "normal", display: "none" }}
        />

        {ProviderTypes?.map((data, i) => (
          <Tab icon={
              <>
                <img
                  alt={data.PROVIDER_TYPE_ICON}
                  src={`${process.env.REACT_APP_HOST_API_URL}/uploads/ProviderTypeIcon/${data.PROVIDER_TYPE_ICON || 'all.png'}`}
                  style={{ mr: 2, borderRadius: "100%" }}
                  // onError={({ currentTarget }) => {
                  //   currentTarget.onerror = null; // prevents looping
                  //   currentTarget.src = errorImage;
                  // }}
                  width="30px"
                  height="30px"
                />
              </>
            }
            label={data.PROVIDER_TYPE_NAME}
            key={data.PROVIDER_TYPE_NAME}
            onClick={() => {
              go(data.PROVIDER_TYPE_ID, data.PROVIDER_TYPE_NAME);
            }}
            sx={{ fontWeight: "normal" }}
            style={{ color: "grey" }}
          />
        ))}
      </Tabs>
    </Box>
  );
}
