// routes
import { PATH_PROVIDER_DASHBOARD } from "../../../routes/paths";

import Iconify from "../../../components/Iconify";

const ICONS = {
  patient: 
  (
    <Iconify icon={"fluent:patient-24-regular"} width={20} height={20} />
  ),
  dashboard: <Iconify icon={"clarity:dashboard-line"} width={20} height={20} />,
  provider: 
  (
    <Iconify icon={"healthicons:virus-patient"} width={20} height={20} />
  ),
  blog: <Iconify icon={"fa6-brands:blogger"} width={20} height={20} />,
  cart: <Iconify icon={"eva:shopping-cart-outline"} width={20} height={20} />,
  chat: <Iconify icon={"bx:message-square"} width={20} height={20} />,
  mail: <Iconify icon={"fe:mail"} width={20} height={20} />,
  user: 
  (
    <Iconify icon={"ant-design:user-add-outlined"} width={20} height={20} />
  ),
  banking:
  (
    <Iconify icon={"fluent:building-bank-48-filled"} width={20} height={20} />
  ),
  ecommerce: <Iconify icon={"arcticons:samsung-shop"} width={20} height={20} />,
  analytics: <Iconify icon={"carbon:analytics"} width={20} height={20} />,
  appointment: 
  (
    <Iconify icon={"teenyicons:appointments-outline"} width={20} height={20} />
  ),
  setting: 
  (
    <Iconify icon={"ant-design:setting-twotone"} width={20} height={20} />
  ),
  userSetting: 
  (
    <Iconify icon={"ant-design:setting-outlined"} width={20} height={20} />
  ),
  payment: <Iconify icon={"mdi:cash-sync"} width={20} height={20} />,
  Notification: 
  (
    <Iconify icon={"ci:notification-outline-dot"} width={20} height={20} />
  ),
  Patient_Experience: 
  (
    <Iconify
      icon={"healthicons:virus-patient-outline"}
      width={20}
      height={20}
    />
  ),
};

const ProvidernavConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    // subheader: 'Dashboard',
    items: [
      {
        title: "Dashboard",
        path: PATH_PROVIDER_DASHBOARD.general.dashboard,
        icon: ICONS.dashboard,
      },
      {
        title: "Appointments",
        path: PATH_PROVIDER_DASHBOARD.general.booking,
        icon: ICONS.appointment,
      },
      {
        title: "Patients",
        path: PATH_PROVIDER_DASHBOARD.general.patients,
        icon: ICONS.patient,

        // children: [
        //   // { title: 'profile', path: PATH_PROVIDER_DASHBOARD.user.profile },
        //   { title: 'All Patient', path: '' },
        // ],
      },
      {
        title: "Patient Experience",
        path: PATH_PROVIDER_DASHBOARD.general.Patient_Experience,
        icon: ICONS.Patient_Experience,
      },
      {
        title: "Message",
        path: PATH_PROVIDER_DASHBOARD.chat.root,
        icon: ICONS.chat,
      },
      {
        title: "Notification",
        path: PATH_PROVIDER_DASHBOARD.general.notification,
        icon: ICONS.Notification,
      },
      {
        title: "Payments & Billings",
        path: PATH_PROVIDER_DASHBOARD.general.paymentandbillings,
        icon: ICONS.payment,
      },
      // { title: 'profile', path: PATH_PROVIDER_DASHBOARD.user.profile, icon: ICONS.user },
      {
        title: "My Profile",
        path: PATH_PROVIDER_DASHBOARD.user.profile,
        icon: ICONS.user,
      },

      // { title: 'Doctor', path: PATH_PROVIDER_DASHBOARD.user.cards, icon: ICONS.user },
      // { title: 'Dentists', path: PATH_PROVIDER_DASHBOARD.user.cards, icon: ICONS.user },
      // { title: 'chiropractors', path: PATH_PROVIDER_DASHBOARD.user.cards, icon: ICONS.user },
      // { title: 'Labs', path: PATH_PROVIDER_DASHBOARD.user.cards, icon: ICONS.user },
      // { title: 'Pharmacy', path: PATH_PROVIDER_DASHBOARD.user.cards, icon: ICONS.user },
      // { title: 'Radiology', path: PATH_PROVIDER_DASHBOARD.user.cards, icon: ICONS.user },
      // { title: 'Hospitals', path: PATH_PROVIDER_DASHBOARD.user.cards, icon: ICONS.user },

      // { title: 'App Analytics', path: PATH_PROVIDER_DASHBOARD.general.analytics, icon: ICONS.analytics },
    ],
  },

  {
    //   subheader:'Setting',
    items: [
      // {
      //   title: 'mail',
      //   path: PATH_PROVIDER_DASHBOARD.mail.root,
      //   icon: ICONS.mail,
      //   info: (
      //     <Label variant="outlined" color="error">
      //       +32
      //     </Label>
      //   ),
      // },
      // { title: 'calendar', path: PATH_PROVIDER_DASHBOARD.calendar, icon: ICONS.calendar },
      //  { title: 'Account Settings & Profile', path: PATH_PROVIDER_DASHBOARD.setting, icon: ICONS.setting },
    ],
  },
];
export default ProvidernavConfig;
