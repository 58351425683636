import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/auth/Login';
// components
import LoadingScreen from '../components/LoadingScreen';
import Swal from 'sweetalert2';
import { PATH_PATIENT_DASHBOARD, PATH_PROVIDER_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {

  const { user } = useAuth();
  const UserRole = user?.role;
  const { isAuthenticated, isInitialized } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }
  if (isAuthenticated) {
    if (UserRole === 3) {
      if (user?.PATIENT_DELETED_STATUS === 'TRUE') {
        Swal.fire({
          title: 'Warning',
          text: `Dear ${user?.displayName} Your Account is Delete Please Contact support@droupons.com to restore Your Account.`,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
          // timer: 1000,
        });
        if (pathname === '/patient/dashboard') {
          // return (<Navigate to={PATH_PATIENT_DASHBOARD.root} />);
        } else {
          return <Navigate to={PATH_PATIENT_DASHBOARD.dashboard} />;
        }
      }
      else if (user?.PATIENT_STATUS === 'INACTIVE') {
        Swal.fire({
          title: 'Warning',
          text: `Dear ${user?.displayName} Your Profile is Inactive Please wait or Contact support@droupons.com`,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
          // timer: 1000,
        });
        if (pathname === '/patient/dashboard') {
          // return (<Navigate to={PATH_PATIENT_DASHBOARD.root} />);
        } else {
          return <Navigate to={PATH_PATIENT_DASHBOARD.dashboard} />;
        }
      }
      else if (user?.profile_status !== 100) {
        Swal.fire({
          title: 'Warning',
          text: 'Please complete your profile details. Then, you can access full application',
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
          // timer: 1000,
        });
        if (pathname === '/patient/setting') {
          // return (<Navigate to={PATH_PATIENT_DASHBOARD.root} />);
        } else {
          return <Navigate to={PATH_PATIENT_DASHBOARD.setting} />;
        }
      }
    }
    if (UserRole === 2) {

      if (user?.PROVIDER_DELETED_STATUS === "TRUE") {
        Swal.fire({
          title: 'Warning',
          text: `Dear Dr. ${user?.displayName} Your Account is Delete Please Contact support@droupons.com to restore Your Account.`,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
          // timer: 1000,
        });

        if (pathname === '/provider') {
          // return (<Navigate to={PATH_PROVIDER_DASHBOARD.root} />);
        } else {
          return <Navigate to={'/'} />;
        }
      }
      if (user?.PROVIDER_STATUS === "INACTIVE") {
        Swal.fire({
          title: 'Warning',
          text: `Dear ${user?.displayName} Your Profile is Inactive Please wait or Contact support@droupons.com`,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
          // timer: 1000,
        });
        if (pathname === '/provider') {
          // return (<Navigate to={PATH_PROVIDER_DASHBOARD.root} />);
        } else {
          return <Navigate to={'/'} />;
        }
      }
      if (user?.profile_status !== 100) {
        Swal.fire({
          title: 'Warning',
          text: 'Please complete your profile details. Then, you can access full application',
          icon: 'warning',
          confirmButtonColor: '#6495ed',
          confirmButtonText: 'Ok',
          // timer: 1000,
        });
        if (pathname === '/provider/setting') {
          // return (<Navigate to={PATH_PROVIDER_DASHBOARD.root} />);
        } else {
          return <Navigate to={PATH_PROVIDER_DASHBOARD.setting} />;
        }
      }
    }
  }
  return <>{children}</>;
}
