import { useLocation, Outlet } from "react-router-dom";
// @mui
import { Stack } from "@mui/material";
// components

//
import MainFooter from "./MainFooter";
import MainHeader from "./MainHeader";
import React from "react";
import HomeFooter from "./HomeFooter";

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();

  const isHome = pathname === "/";
  const marginTop = isHome ? "200px" : "90px";
  return (
    <Stack sx={{ minHeight: 1 }}>
      <MainHeader />
      <div style={{ marginTop: marginTop }}>
        <Outlet />
      </div>
      {isHome ? <HomeFooter /> : <MainFooter />}
    </Stack>
  );
}
