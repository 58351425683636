// routes
import { PATH_PATIENT_DASHBOARD } from "../../../routes/paths";
import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------

const ICONS = {
  patient: (
    <Iconify icon={"fluent:patient-24-regular"} width={20} height={20} />
  ),
  dashboard: <Iconify icon={"clarity:dashboard-line"} width={20} height={20} />,
  provider: (
    <Iconify icon={"healthicons:virus-patient"} width={20} height={20} />
  ),
  blog: <Iconify icon={"fa6-brands:blogger"} width={20} height={20} />,
  cart: <Iconify icon={"eva:shopping-cart-outline"} width={20} height={20} />,
  chat: <Iconify icon={"bx:message-square"} width={20} height={20} />,
  mail: <Iconify icon={"fe:mail"} width={20} height={20} />,
  user: (
    <Iconify icon={"ant-design:user-add-outlined"} width={20} height={20} />
  ),
  banking: (
    <Iconify icon={"fluent:building-bank-48-filled"} width={20} height={20} />
  ),
  ecommerce: <Iconify icon={"arcticons:samsung-shop"} width={20} height={20} />,
  analytics: <Iconify icon={"carbon:analytics"} width={20} height={20} />,
  appointment: (
    <Iconify icon={"teenyicons:appointments-outline"} width={20} height={20} />
  ),
  setting: (
    <Iconify icon={"ant-design:setting-twotone"} width={20} height={20} />
  ),
  userSetting: (
    <Iconify icon={"ant-design:setting-outlined"} width={20} height={20} />
  ),
  payment: <Iconify icon={"mdi:cash-sync"} width={20} height={20} />,
  Notification: (
    <Iconify icon={"ci:notification-outline-dot"} width={20} height={20} />
  ),
  orders: <Iconify icon={"bxs:shopping-bags"} width={20} height={20} />,
  Rewards: <Iconify icon={"emojione-monotone:trophy"} width={20} height={20} />,
};

const PatientNavConfig = [
  {
    items: [
      {
        title: "Dashboard",
        path: PATH_PATIENT_DASHBOARD.dashboard,
        icon: ICONS.dashboard,
      },
      {
        title: "Appointments",
        path: PATH_PATIENT_DASHBOARD.general.appointment,
        icon: ICONS.appointment,
      },
      {
        title: "Previous Visits",
        path: PATH_PATIENT_DASHBOARD.general.paymentandbillings,
        icon: ICONS.payment,
      },
      {
        title: "Rewards",
        path: PATH_PATIENT_DASHBOARD.general.Rewards,
        icon: ICONS.Rewards,
      },
      {
        title: "Message",
        path: PATH_PATIENT_DASHBOARD.chat.root,
        icon: ICONS.chat,
      },
      {
        title: "Notifications",
        path: PATH_PATIENT_DASHBOARD.general.notification,
        icon: ICONS.Notification,
      },

      {
        title: "Saved Providers",
        path: PATH_PATIENT_DASHBOARD.user.cards,
        icon: ICONS.provider,
      },

      // { title: 'Order', path: PATH_PATIENT_DASHBOARD.general.orders, icon: ICONS.orders },

      // { title: 'My Profile', path: PATH_PATIENT_DASHBOARD.user.profile, icon: ICONS.user },
    ],
  },

  {
    items: [
      //     {
      //       title: 'mail',
      //       path: PATH_PATIENT_DASHBOARD.mail.root,
      //       icon: ICONS.mail,
      //       info: (
      //         <Label variant="outlined" color="error">
      //           +32
      //         </Label>
      //       ),
      //     },
      //     { title: 'calendar', path: PATH_PATIENT_DASHBOARD.calendar, icon: ICONS.calendar },
      // { title: 'Profile Settings ', path: PATH_PATIENT_DASHBOARD.setting, icon: ICONS.setting },
    ],
  },
];
export default PatientNavConfig;
