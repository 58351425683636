import axios from 'axios';
// config
import { HOST_API } from '../config';
import {cryptr} from './ComonFunction'
// ----------------------------------------------------------------------
const access_token = cryptr.encrypt(process.env.REACT_APP_HOST_API_KEY);
console.log(access_token)
// const access_token = process.env.REACT_APP_HOST_API_KEY;
// console.log(access_token,"access_token");
const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: {
    'access-token': access_token,
  }
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong Server is Not Working')
);


export default axiosInstance;
