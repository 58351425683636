import { useSnackbar } from "notistack";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import { Box, Divider, Typography, Stack, MenuItem } from "@mui/material";
// routes
import {
  PATH_ADMIN_DASHBOARD,
  PATH_AUTH,
  PATH_PATIENT_DASHBOARD,
  PATH_PROVIDER_DASHBOARD,
} from "../../../routes/paths";
// hooks
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
// components
import MyAvatar from "../../../components/MyAvatar";
//Popover
import MenuPopover from "../../../components/MenuPopover";
//Animate
import { IconButtonAnimate } from "../../../components/animate";

// ----------------------------------------------------------------------
//Admin Menu
const ADMIN_MENU_OPTIONS = [
  {
    label: "Home",
    linkTo: "/",
  },
  // {
  //   label: 'Profile',
  //   linkTo: PATH_ADMIN_DASHBOARD.user.profile,
  // },
  {
    label: "Management",
    linkTo: PATH_ADMIN_DASHBOARD.setting,
  },
];
//Doctor or Provider Menu
const PROVIDER_MENU_OPTIONS = [
  {
    label: "Home",
    linkTo: "/",
  },
  {
    label: "Profile",
    linkTo: PATH_PROVIDER_DASHBOARD.user.profile,
  },
  {
    label: "Management",
    linkTo: PATH_PROVIDER_DASHBOARD.setting,
  },
];
//Patient Menu
const PATIENT_MENU_OPTIONS = [
  {
    label: "Home",
    linkTo: "/",
  },
  {
    label: "Profile",
    linkTo: PATH_PATIENT_DASHBOARD.user.profile,
  },
  // {
  //   label: "Management",
  //   linkTo: PATH_PATIENT_DASHBOARD.setting,
  // },
];

// ----------------------------------------------------------------------
//Main
export default function AccountPopover() {
  const navigate = useNavigate();

  const { user, logout } = useAuth();

  const UserRole = user.role;

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to logout!", { variant: "error" });
    }
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" nowrap={true.toString()}>
            {user?.role === 2 ? "Dr." : null} {user?.displayName}
          </Typography>
          {/* <Typography
                     variant="body2"
                     sx={{ color: "text.secondary" }}
                     nowrap={true.toString()}
                   >
                     {user?.email}
                   </Typography> */}

          {/* <Typography
            variant="body2"
            sx={{ color: "text.secondary" }}
            nowrap={true.toString()}
          >
            {user?.email}
          </Typography> */}
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <>
          {(() => {
            if (UserRole === 1) {
              return (
                <Stack sx={{ p: 1 }}>
                  {ADMIN_MENU_OPTIONS.map((option) => (
                    <MenuItem
                      key={option.label}
                      to={option.linkTo}
                      component={RouterLink}
                      onClick={handleClose}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Stack>
              );
            }
            if (UserRole === 2) {
              return (
                <Stack sx={{ p: 1 }}>
                  {PROVIDER_MENU_OPTIONS.map((option) => (
                    <MenuItem
                      key={option.label}
                      to={option.linkTo}
                      component={RouterLink}
                      onClick={handleClose}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Stack>
              );
            }
            if (UserRole === 3) {
              return (
                <Stack sx={{ p: 1 }}>
                  {PATIENT_MENU_OPTIONS.map((option) => (
                    <MenuItem
                      key={option.label}
                      to={option.linkTo}
                      component={RouterLink}
                      onClick={handleClose}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Stack>
              );
            }
          })()}
        </>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
