import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";

// hooks
import useAuth from "../hooks/useAuth";
// routes
import {
  PATH_ADMIN_DASHBOARD,
  PATH_PATIENT_DASHBOARD,
  PATH_PROVIDER_DASHBOARD,
} from "../routes/paths";

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated } = useAuth();

  const { user } = useAuth();
  const UserRole = user?.role;

  if (isAuthenticated) {
    if (UserRole === 1) {
      return <Navigate to={PATH_ADMIN_DASHBOARD.root} />;
    }
    if (UserRole === 2) {
      return <Navigate to={PATH_PROVIDER_DASHBOARD.root} />;
    }
    if (UserRole === 3) {
      return <Navigate to={PATH_PATIENT_DASHBOARD.dashboard} />;
    }
  }

  return <>{children}</>;
}
