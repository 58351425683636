// routes
import { PATH_ADMIN_DASHBOARD } from "../../../routes/paths";
// components

import Iconify from "../../../components/Iconify";

// ---------------------------------------------------------------------

const ICONS = {
  patient: (
    <Iconify icon={"fluent:patient-24-regular"} width={20} height={20} />
  ),
  dashboard: <Iconify icon={"clarity:dashboard-line"} width={20} height={20} />,
  provider: (
    <Iconify icon={"healthicons:virus-patient"} width={20} height={20} />
  ),
  blog: <Iconify icon={"fa6-brands:blogger"} width={20} height={20} />,
  cart: <Iconify icon={"eva:shopping-cart-outline"} width={20} height={20} />,
  chat: <Iconify icon={"bx:message-square"} width={20} height={20} />,
  mail: <Iconify icon={"fe:mail"} width={20} height={20} />,
  user: (
    <Iconify icon={"ant-design:user-add-outlined"} width={20} height={20} />
  ),
  banking: (
    <Iconify icon={"fluent:building-bank-48-filled"} width={20} height={20} />
  ),
  ecommerce: <Iconify icon={"arcticons:samsung-shop"} width={20} height={20} />,
  analytics: <Iconify icon={"carbon:analytics"} width={20} height={20} />,
  appointment: (
    <Iconify icon={"teenyicons:appointments-outline"} width={20} height={20} />
  ),
  setting: (
    <Iconify icon={"ant-design:setting-twotone"} width={20} height={20} />
  ),
  userSetting: (
    <Iconify icon={"ant-design:setting-outlined"} width={20} height={20} />
  ),
  contact: <Iconify icon={"bxs:contact"} width={20} height={20} />,
  services: (
    <Iconify icon={"ic:outline-medical-services"} width={20} height={20} />
  ),
  providertype: <Iconify icon={"icons8:doctor"} width={20} height={20} />,
  Notification: (
    <Iconify icon={"ci:notification-outline-dot"} width={20} height={20} />
  ),
};

const navConfig = [
  {
    // subheader: 'Admin Dashboard',
    items: [
      {
        title: "Dashboard",
        path: PATH_ADMIN_DASHBOARD.general.dashboard,
        icon: ICONS.dashboard,
      },
      {
        title: "Providers",
        path: PATH_ADMIN_DASHBOARD.provider.allprovider,
        icon: ICONS.user,
        //   children: [
        //     // { title: 'profile', path: PATH_ADMIN_DASHBOARD.user.profile },
        //     { title: 'All Provider', path: PATH_ADMIN_DASHBOARD.provider.allprovider },
        //  //   { title: 'Provider Card View', path: PATH_ADMIN_DASHBOARD.provider.providerCards },
        //     { title: 'create Provider', path: PATH_ADMIN_DASHBOARD.provider.newProvider },
        //     {
        //       title: 'Provider Categorize',
        //       path: '',
        //       children: [
        //         { title: 'Doctor', path: PATH_ADMIN_DASHBOARD.provider.providerCards },
        //         { title: 'Dentists', path: PATH_ADMIN_DASHBOARD.provider.providerCards },
        //         { title: 'chiropractors', path: PATH_ADMIN_DASHBOARD.provider.providerCards },
        //         { title: 'Labs', path: PATH_ADMIN_DASHBOARD.provider.providerCards },
        //         { title: 'Pharmacy', path: PATH_ADMIN_DASHBOARD.provider.providerCards },
        //         { title: 'Radiology', path: PATH_ADMIN_DASHBOARD.provider.providerCards },
        //         { title: 'Hospitals', path: PATH_ADMIN_DASHBOARD.provider.providerCards },
        //       ],
        //     },
        //   ],
      },

      {
        title: "Patient",
        path: PATH_ADMIN_DASHBOARD.patient.AllPatient,
        icon: ICONS.patient,
      },
      {
        title: "Provider Type",
        path: PATH_ADMIN_DASHBOARD.providertype.AllProviderType,
        icon: ICONS.providertype,
      },

      {
        title: "Services",
        path: PATH_ADMIN_DASHBOARD.services.AllServices,
        icon: ICONS.services,
      },
      {
        title: "Contact ",
        path: PATH_ADMIN_DASHBOARD.general.contact,
        icon: ICONS.contact,
      },
      {
        title: "Payment",
        path: PATH_ADMIN_DASHBOARD.general.banking,
        icon: ICONS.banking,
      },
      {
        title: "Management",

        icon: ICONS.userSetting,
        children: [
          {
            title: "User Roles",
            path: PATH_ADMIN_DASHBOARD.roles.AllRole,
            icon: ICONS.user,
          },

          {
            title: "Allergies",
            path: PATH_ADMIN_DASHBOARD.allergies.AllAllergies,
            icon: ICONS.user,
          },
          {
            title: "Symptoms",
            path: PATH_ADMIN_DASHBOARD.symptoms.AllSymptoms,
            icon: ICONS.user,
          },

          // {
          //   title: "Provider Type",
          //   path: PATH_ADMIN_DASHBOARD.providertype.AllProviderType,
          //   icon: ICONS.user,
          // },

          // {
          //   title: "Services",
          //   path: PATH_ADMIN_DASHBOARD.services.AllServices,
          //   icon: ICONS.user,
          // },
          {
            title: "Specialty Board Certification",
            path: PATH_ADMIN_DASHBOARD.SBC.AllSBC,
            icon: ICONS.user,
          },
          {
            title: "Sub Specialty Board Certification",
            path: PATH_ADMIN_DASHBOARD.SUBSBC.AllSUBSBC,
            icon: ICONS.user,
          },
        ],
      },
      {
        title: "Account Management & Profile ",
        path: PATH_ADMIN_DASHBOARD.setting,
        icon: ICONS.setting,
      },
      {
        title: "Notification",
        path: PATH_ADMIN_DASHBOARD.general.notification,
        icon: ICONS.Notification,
      },

      // {
      //   title: 'All User',
      //   path: PATH_ADMIN_DASHBOARD.user.list,
      //   icon: ICONS.user,
      //   children: [
      //     // { title: 'profile', path: PATH_ADMIN_DASHBOARD.user.profile },
      //     { title: 'All ', path: PATH_ADMIN_DASHBOARD.user.list },
      //     { title: 'create User', path: PATH_ADMIN_DASHBOARD.user.newUser },
      //   ],
      // },

      // { title: 'App Analytics', path: PATH_ADMIN_DASHBOARD.general.analytics, icon: ICONS.analytics },

      {
        title: "Wellness Store",
        path: PATH_ADMIN_DASHBOARD.eCommerce.root,
        icon: ICONS.cart,
        children: [
          // { title: 'Wellness Store Analytics', path: PATH_ADMIN_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
          {
            title: "Wellness Products",
            path: PATH_ADMIN_DASHBOARD.eCommerce.list,
          },
          { title: "Orders", path: PATH_ADMIN_DASHBOARD.eCommerce.list },
          {
            title: "Cancelled orders",
            path: PATH_ADMIN_DASHBOARD.eCommerce.list,
          },
          {
            title: "Completed Orders",
            path: PATH_ADMIN_DASHBOARD.eCommerce.list,
          },
          //  { title: 'Create New', path: PATH_ADMIN_DASHBOARD.eCommerce.newProduct },
        ],
      },
      { title: 'All Appoinments', path: PATH_ADMIN_DASHBOARD.general.appointment, icon: ICONS.appointment },

      // {
      //   title: 'services',
      //   path: PATH_ADMIN_DASHBOARD.services.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'All Services', path: PATH_ADMIN_DASHBOARD.services.AllServices },
      //     { title: 'Add New Services', path: PATH_ADMIN_DASHBOARD.services.newServices },
      //   ],
      // },
    ],
  },

  // Blog
  // ----------------------------------------------------------------------
  // {
  //   items: [
  //     {
  //       title: 'blog',
  //       path: PATH_ADMIN_DASHBOARD.blog.root,
  //       icon: ICONS.blog,
  //       children: [
  //         { title: 'posts', path: PATH_ADMIN_DASHBOARD.blog.posts },
  //         { title: 'post', path: PATH_ADMIN_DASHBOARD.blog.postById },
  //         { title: 'new post', path: PATH_ADMIN_DASHBOARD.blog.newPost },
  //       ],
  //     },

  //   ],
  // },
  // { title: 'setting', path: PATH_ADMIN_DASHBOARD.setting },
  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_ADMIN_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: (
  //         <Label variant="outlined" color="error">
  //           +32
  //         </Label>
  //       ),
  //     },
  //     { title: 'chat', path: PATH_ADMIN_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_ADMIN_DASHBOARD.calendar, icon: ICONS.calendar },

  //   ],
  // },
];

export default navConfig;
