/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Card, Grid, Tooltip } from "@mui/material";
import * as React from "react";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

import HeaderBreadcrumbs from "../../../../../components/HeaderBreadcrumbs";
import { PATH_PATIENT_DASHBOARD } from "../../../../../routes/paths";
import Iconify from "../../../../../components/Iconify";
import { useState } from "react";
import { useCallback } from "react";
import axiosInstance from "../../../../../utils/axios";
import { useEffect } from "react";
import { sendNotification } from "../../../../../push-notifications";
import useAuth from "../../../../../hooks/useAuth";
import useIsMountedRef from "../../../../../hooks/useIsMountedRef";
import axios from "../../../../../utils/axios";
import MatDataTables from "../../../../../components/DataTable/matData";
import moment from "moment";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import socket from "../../../../../utils/socket";
import twelve from "twentyfour-to-twelve";
import { capitalize } from "lodash";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": { padding: theme.spacing(2), }, "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, pr: 8 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ProviderAppointmentDataTable = (props) => {
  const [open, setOpen] = React.useState(false);
  const [dialogType, setDialogType] = React.useState();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { user } = useAuth();
  // const [value, setValue] = React.useState('1');
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const [AppointmentList, setAppointmentList] = useState([]);
  const [SingleAppointment, setSingleAppointment] = useState();

  const getAllAppointment = useCallback(async () => {
    try {
      const response = await axiosInstance.post("/provider_appointment", {
        provider_id: user?.id,
      });

      if (isMountedRef.current) {
        setAppointmentList(response.data);
      }
    } catch (err) {
      //
    }
  }, [isMountedRef]);

  useEffect(() => {
    getAllAppointment();
  }, [getAllAppointment]);

  //function for datatable value formatting

  function getSchedule(params) {
    var Slot = "";
    if (params?.row?.APPOINTMENT_SCHEDULE_GET_TIME === "Morning") {
      Slot =
        twelve(params?.row?.APPOINTMENT_SCHEDULE_MST) +
        " - " +
        twelve(params?.row?.APPOINTMENT_SCHEDULE_MET);
    }
    if (params?.row?.APPOINTMENT_SCHEDULE_GET_TIME === "Afternoon") {
      Slot =
        twelve(params?.row?.APPOINTMENT_SCHEDULE_AST) +
        " - " +
        twelve(params?.row?.APPOINTMENT_SCHEDULE_AET);
    }
    if (params?.row?.APPOINTMENT_SCHEDULE_GET_TIME === "Evening") {
      Slot =
        twelve(params?.row?.APPOINTMENT_SCHEDULE_EST) +
        " - " +
        twelve(params?.row?.APPOINTMENT_SCHEDULE_EET);
    }
    return `${moment(params?.row?.APPOINTMENT_DATE).format("MMM DD, YYYY") || ""
      } ${capitalize(params?.row?.APPOINTMENT_SCHEDULE_DAY) || ""}, ${params?.row?.APPOINTMENT_SCHEDULE_GET_TIME
      }- ${Slot}`;
  }
  function getCREATED_AT(params) {
    return `${moment(params?.row?.APPOINTMENT_CREATED_AT).format("MMM DD, YYYY") || ""
      }`;
  }

  const cancelAppoinment = React.useCallback(
    (
      appointment_id,
      appointment_status,
      cancel_by,
      current_user_id,
      APPOINTMENT_PATIENT_ID
    ) =>
      async () => {
        const response = await axios.post("/appointment_cencel", {
          appointment_id: appointment_id,
          status: appointment_status,
          cancel_by: cancel_by,
          current_user_id: current_user_id,
        });
        if (response.data.Status === true) {
          enqueueSnackbar(response.data.Message, { variant: "info" });
          getAllAppointment();
          let body = {
            user_role: user?.role,
            type: "Appointment",
            sender_user_id: user?.id,
            title: `${user?.displayName} Cancelled Appointment`,
            description: `${user?.displayName} Cancelled Appointment .`,
            receiver_user_id: APPOINTMENT_PATIENT_ID,
            notification_type: "Appointment",
            date_time: new Date(),
          };
          sendNotification(body);
          socket.emit("sendNotification", body);
        }
        getAllAppointment();
      },
    []
  );
  const rescheduleAppoinment = React.useCallback(
    (
      appointment_id,
      appointment_status,
      cancel_by,
      current_user_id,
      APPOINTMENT_PATIENT_ID
    ) =>
      async () => {
        const response = await axios.post("/update_appointment", {
          appointment_id: appointment_id,
          status: appointment_status,
          cancel_by: cancel_by,
          current_user_id: current_user_id,
        });

        if (response.data.Status === true) {
          enqueueSnackbar(response.data.Message, { variant: "info" });
          getAllAppointment();
          let body = {
            user_role: user?.role,
            type: "Appointment",
            sender_user_id: user?.id,
            title: `${user?.displayName} Cancelled Appointment`,
            description: `${user?.displayName} Cancelled Appointment .`,
            receiver_user_id: APPOINTMENT_PATIENT_ID,
            notification_type: "Appointment",
            date_time: new Date(),
          };
          sendNotification(body);
          socket.emit("sendNotification", body);
        }
        getAllAppointment();
      },
    []
  );

  const acceptAppoinment = React.useCallback(
    (appointment_id, appointment_status, APPOINTMENT_PATIENT_ID) => async () => {
      const response = await axios.post("/appointment_status", {
        appointment_id: appointment_id,
        status: appointment_status,
      });

      if (response.data.Status === true) {
        let body = {
          user_role: user?.role,
          type: "Appointment",
          sender_user_id: user?.id,
          title: `${user?.displayName} ${appointment_status} Appointment`,
          description: `${user?.displayName} ${appointment_status} Appointment .`,
          receiver_user_id: APPOINTMENT_PATIENT_ID,
          notification_type: "Appointment",
          date_time: new Date(),
        };
        socket.emit("sendNotification", body);
        sendNotification(body);
        enqueueSnackbar(response.data.Message, { variant: "info" });
        getAllAppointment();
      }
    },
    []
  );
  const ViewMoreAppoinment = React.useCallback(
    (appointment_id, appointment_status, APPOINTMENT_PATIENT_ID, type) =>
      async () => {
        handleClickOpen();
        setDialogType(type);
        const response = await axios.post("/single_appointment", {
          id: appointment_id,
        });
        setSingleAppointment(response.data);
      },
    []
  );
  const columns = React.useMemo(
    () => [
      { field: "id", hide: true },
      { field: "#", width: 10 },
      {
        field: "Schedule_On",
        headerName: "Schedule On",
        width: 420,
        valueGetter: getSchedule,
      },
      // { headerName: "PATIENT FIRST NAME", field: "APPOINTMENT_PATIENT_FIRST_NAME", "align": "left", width: 190 },
      // { headerName: "PATIENT LAST NAME", field: "APPOINTMENT_PATIENT_LAST_NAME", "align": "left", width: 190 },
      { headerName: "Name", field: "PATIENT_NAME", align: "left", width: 190 },
      {
        headerName: "Service",
        field: "getService",
        align: "left",
        width: 80,
        type: "actions",
        getActions: (params) => [
          <>
            {
              <Tooltip title="View Service">
                <GridActionsCellItem
                  className="ActionsCellItem"
                  icon={
                    <Iconify
                      icon={"ic:outline-medical-services"}
                      sx={{ width: 20, height: 20 }}
                      color="#6495ed"
                    />
                  }
                  label=" "
                  onClick={ViewMoreAppoinment(
                    params?.row?.APPOINTMENT_ID,
                    params?.row?.APPOINTMENT_PROVIDER_ID,
                    params?.row?.APPOINTMENT_PATIENT_ID,
                    "Service"
                  )}
                  showInMenu
                />
              </Tooltip>
            }
          </>,
        ],
      },
      {
        headerName: "Symptoms",
        field: "APPOINTMENT_SYMPTOMS_ID",
        align: "left",
        width: 80,
        type: "actions",
        getActions: (params) => [
          <>
            {
              <Tooltip title="View Symptoms">
                <GridActionsCellItem
                  className="ActionsCellItem"
                  icon={
                    <Iconify
                      icon={"foundation:graph-trend"}
                      sx={{ width: 20, height: 20 }}
                      color="#6495ed"
                    />
                  }
                  label=" "
                  onClick={ViewMoreAppoinment(
                    params?.row?.APPOINTMENT_ID,
                    params?.row?.APPOINTMENT_PROVIDER_ID,
                    params?.row?.APPOINTMENT_PATIENT_ID,
                    "Symptoms"
                  )}
                  showInMenu
                />
              </Tooltip>
            }
          </>,
        ],
      },
      // { headerName: "Symptoms", field: "APPOINTMENT_SYMPTOMS_ID", "align": "left", width: 190 },
      {
        headerName: "Descripition",
        field: "APPOINTMENT_DESCRIPTION",
        align: "left",
        width: 190,
      },
      // { headerName: "AMOUNT", field: "APPOINTMENT_AMOUNT", "align": "left", "type": "number", width: 190 },
      {
        headerName: "PAYMENT STATUS",
        field: "APPOINTMENT_PAYMENT_STATUS",
        align: "left",
        width: 190,
      },
      // { headerName: "PAYMENT TYPE", field: "APPOINTMENT_PAYMENT_TYPE", "align": "left", width: 190 },
      // { headerName: "PAYMENT SOURCE", field: "APPOINTMENT_PAYMENT_SOURCE", "align": "left", width: 190 },
      // { headerName: "ORDER ID", field: "APPOINTMENT_ORDER_ID", "align": "left", width: 190 },
      // { headerName: "PAYER ID", field: "APPOINTMENT_PAYER_ID", "align": "left", width: 190 },
      {
        headerName: "Status",
        field: "APPOINTMENT_STATUS",
        align: "left",
        width: 100,
        renderCell: (params) => (
          <Typography
            label="success"
            color="success"
            variant="subtitle2"
            className={params?.row?.APPOINTMENT_STATUS}
          >
            {params?.row?.APPOINTMENT_STATUS}
          </Typography>
        ),
        sortComparator: (v1, v2) =>
          v1.params?.row?.APPOINTMENT_STATUS.localeCompare(
            v2.params?.row?.APPOINTMENT_STATUS
          ),
      },
      // { headerName: "CANCEL AT", field: "APPOINTMENT_CANCEL_AT", "align": "left", width: 190 },
      {
        headerName: "Created At",
        field: "getCREATED_AT",
        valueGetter: getCREATED_AT,
        align: "left",
        width: 120,
      },
      // { headerName: "UPDATED AT", field: "APPOINTMENT_UPDATED_AT", "align": "left", width: 190 },
      // { headerName: "CANCEL STATUS", field: "APPOINTMENT_CANCEL_STATUS", "align": "left", width: 190 },
      // { headerName: "CANCEL BY", field: "APPOINTMENT_CANCEL_BY", "align": "left", width: 190 },
      {
        field: "actions",
        type: "actions",
        width: 20,
        align: "right",

        getActions: (params) => [
          <GridActionsCellItem
            className="ActionsCellItem"
            icon={
              <Iconify
                icon={(() => {
                  if (params?.row?.APPOINTMENT_STATUS === "ACCEPTED") {
                    return "ion:checkmark-done-sharp";
                  }
                  if (params?.row?.APPOINTMENT_STATUS === "DONE") {
                    return "ant-design:file-done-outlined";
                  }
                  if (params?.row?.APPOINTMENT_STATUS === "PENDING") {
                    return "ic:baseline-done";
                  }
                  if (params?.row?.APPOINTMENT_STATUS === "EXPIRED") {
                    return "teenyicons:appointments-outline";
                  }
                  if (params?.row?.APPOINTMENT_STATUS === "CANCELED") {
                    return params?.row?.APPOINTMENT_CANCEL_BY === "DOCTOR"
                      ? "material-symbols:cancel-rounded"
                      : "material-symbols:cancel-rounded";
                  }
                })()}
                sx={{ width: 20, height: 20 }}
              />
            }
            label={(() => {
              if (params?.row?.APPOINTMENT_STATUS === "ACCEPTED") {
                return "Mark Done";
              }
              if (params?.row?.APPOINTMENT_STATUS === "DONE") {
                return "Done";
              }
              if (params?.row?.APPOINTMENT_STATUS === "PENDING") {
                return "Accept";
              }
              if (params?.row?.APPOINTMENT_STATUS === "EXPIRED") {
                return "Reschedule";
              }
              if (params?.row?.APPOINTMENT_STATUS === "CANCELED") {
                return params?.row?.APPOINTMENT_CANCEL_BY === "DOCTOR"
                  ? "Accept Again Same Time"
                  : `Cancelled By ${params?.row?.APPOINTMENT_PATIENT_FIRST_NAME}`;
              }
            })()}
            onClick={(() => {
              if (params?.row?.APPOINTMENT_STATUS === "ACCEPTED") {
                return acceptAppoinment(
                  params?.row?.APPOINTMENT_ID,
                  "DONE",
                  params?.row?.APPOINTMENT_PATIENT_ID
                );
              }
              if (params?.row?.APPOINTMENT_STATUS === "DONE") {
                // return acceptAppoinment(params?.row?.APPOINTMENT_ID, 'DONE', params?.row?.APPOINTMENT_PATIENT_ID)
                // return    enqueueSnackbar("Already Done", { variant: 'info' });
              }
              if (params?.row?.APPOINTMENT_STATUS === "PENDING") {
                return acceptAppoinment(
                  params?.row?.APPOINTMENT_ID,
                  "ACCEPTED",
                  params?.row?.APPOINTMENT_PATIENT_ID
                );
              }
              if (params?.row?.APPOINTMENT_STATUS === "EXPIRED") {
                return "Reschedule";
              }
              if (params?.row?.APPOINTMENT_STATUS === "CANCELED") {
                return params?.row?.APPOINTMENT_CANCEL_BY === "DOCTOR"
                  ? "Accept Again Same Time"
                  : null;
              }
            })()}
            showInMenu
          />,
          <GridActionsCellItem
            icon={
              <Iconify
                icon={
                  params?.row?.APPOINTMENT_STATUS === "PENDING"
                    ? "iconoir:cancel"
                    : "wpf:recurring-appointment"
                }
                sx={{ width: 20, height: 20 }}
              />
            }
            label={(() => {
              if (params?.row?.APPOINTMENT_STATUS === "CANCELED") {
                return "Reschedule";
              }
              if (params?.row?.APPOINTMENT_STATUS === "ACCEPTED") {
                return "Reschedule";
              }
              if (params?.row?.APPOINTMENT_STATUS === "EXPIRED") {
                return "Reschedule";
              }
              if (params?.row?.APPOINTMENT_STATUS === "PENDING") {
                return "Cancel";
              }
            })()}
            onClick={(() => {
              if (params?.row?.APPOINTMENT_STATUS === "CANCELED") {
                return rescheduleAppoinment(
                  params?.row?.APPOINTMENT_ID,
                  "CANCELED",
                  "DOCTOR",
                  params?.row?.APPOINTMENT_PROVIDER_ID,
                  params?.row?.APPOINTMENT_PATIENT_ID
                );
              }
              if (params?.row?.APPOINTMENT_STATUS === "ACCEPTED") {
                return rescheduleAppoinment(
                  params?.row?.APPOINTMENT_ID,
                  "CANCELED",
                  "DOCTOR",
                  params?.row?.APPOINTMENT_PROVIDER_ID,
                  params?.row?.APPOINTMENT_PATIENT_ID
                );
              }
              if (params?.row?.APPOINTMENT_STATUS === "EXPIRED") {
                return rescheduleAppoinment(
                  params?.row?.APPOINTMENT_ID,
                  "CANCELED",
                  "DOCTOR",
                  params?.row?.APPOINTMENT_PROVIDER_ID,
                  params?.row?.APPOINTMENT_PATIENT_ID
                );
              }
              if (params?.row?.APPOINTMENT_STATUS === "PENDING") {
                return cancelAppoinment(
                  params?.row?.APPOINTMENT_ID,
                  "CANCELED",
                  "DOCTOR",
                  params?.row?.APPOINTMENT_PROVIDER_ID,
                  params?.row?.APPOINTMENT_PATIENT_ID
                );
              }
            })()}
            className={`ActionsCellItem ${params?.row?.APPOINTMENT_STATUS === "DONE" ? "hide_button" : null
              }`}
            //  label="Cancel Appointment" onClick={cancelAppoinment(params?.row?.APPOINTMENT_ID, 'CANCELED', 'DOCTOR', params?.row?.APPOINTMENT_PROVIDER_ID, params?.row?.APPOINTMENT_PATIENT_ID)}
            showInMenu
          />,

          <GridActionsCellItem
            className="ActionsCellItem"
            icon={
              <Iconify
                icon={"carbon:view-filled"}
                sx={{ width: 20, height: 20 }}
              />
            }
            label="View"
            onClick={ViewMoreAppoinment(
              params?.row?.APPOINTMENT_ID,
              params?.row?.APPOINTMENT_PROVIDER_ID,
              params?.row?.APPOINTMENT_PATIENT_ID
            )}
            showInMenu
          />,
        ],
      },
    ],
    [
      ViewMoreAppoinment,
      acceptAppoinment,
      rescheduleAppoinment,
      cancelAppoinment,
    ]
  );

  return (
    <div>
      {props.isBreadComb === false ? null : (
        <HeaderBreadcrumbs
          heading="Appointment"
          links={[
            { name: "Dashboard", href: PATH_PATIENT_DASHBOARD.root },
            { name: "Appointment" },
          ]}
        />
      )}

      <Card sx={{ py: 3, px: 3, textAlign: "center" }}>
        <Box sx={{ width: "100%", typography: "body1" }}>
          {AppointmentList.length === 0 ? (
            "No Appointment"
          ) : (
            <MatDataTables rows={AppointmentList} columns={columns} />
          )}
        </Box>
      </Card>

      <div>
        {/* <Button variant="outlined" onClick={handleClickOpen}>
          Open dialog
        </Button> */}
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            {SingleAppointment?.PATIENT_NAME} {dialogType}
          </BootstrapDialogTitle>
          <DialogContent dividers sx={{ minWidth: "200px" }}>
            {(() => {
              if (dialogType === "Service") {
                return (
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{
                      display: "grid",
                      px: 3,
                      rowGap: 0.5,
                      columnGap: 2,
                    }}
                  >
                    {SingleAppointment?.APPOINTMENT_SERVICE_NAME.map((row) => (
                      <Card
                        container
                        sx={{
                          display: "grid",
                          borderRadius: "2px",
                          p: 1,
                          px: 1,
                          rowGap: 0.5,
                          columnGap: 1,
                          gridTemplateColumns: {
                            xs: "repeat(2, 1fr)",
                            sm: "repeat(2, 1fr)",
                          },
                        }}
                        key={row?.PROVIDER_SERVICES_SERIVICE_NAME}
                      >
                        <Typography>
                          {row?.PROVIDER_SERVICES_SERIVICE_NAME}
                        </Typography>
                        <Typography align="right">
                          {row?.PROVIDER_TOTAL_SERVICES_CHARGES}
                        </Typography>
                      </Card>
                    ))}
                  </Grid>
                );
              } else if (dialogType === "Symptoms") {
                return (
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{
                      display: "flex",
                      px: 3,
                      rowGap: 0.5,
                      columnGap: 2,
                    }}
                  >
                    {SingleAppointment?.APPOINTMENT_SYMPTOMS_NAME.map(
                      (row, i) => (
                        <Card
                          container
                          sx={{
                            display: "grid",
                            borderRadius: "2px",
                            p: 1,
                            px: 1,
                            rowGap: 0.5,
                            columnGap: 1,
                            gridTemplateColumns: {
                              xs: "repeat(1, 1fr)",
                              sm: "repeat(1, 1fr)",
                            },
                          }}
                          key={row?.name}
                        >
                          <Typography align="left">{row?.name}</Typography>
                        </Card>
                      )
                    )}
                  </Grid>
                );
              } else {
                return (
                  <Box sx={{ width: "100%" }}>
                    {/* <Grid item xs={12} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                      {
                        (() => {
                          var Slot = '';
                          if (SingleAppointment?.APPOINTMENT_SCHEDULE_GET_TIME === 'Morning') {
                            Slot = twelve(SingleAppointment?.APPOINTMENT_SCHEDULE_MST) + ' - ' + twelve(SingleAppointment?.APPOINTMENT_SCHEDULE_MET)
                          }
                          if (SingleAppointment?.APPOINTMENT_SCHEDULE_GET_TIME === 'Afternoon') {
                            Slot = twelve(SingleAppointment?.APPOINTMENT_SCHEDULE_AST) + ' - ' + twelve(SingleAppointment?.APPOINTMENT_SCHEDULE_AET)
                          }
                          if (SingleAppointment?.APPOINTMENT_SCHEDULE_GET_TIME === 'Evening') {
                            Slot = twelve(SingleAppointment?.APPOINTMENT_SCHEDULE_EST) + ' - ' + twelve(SingleAppointment?.APPOINTMENT_SCHEDULE_EET)
                          }
                          return `${moment(SingleAppointment?.APPOINTMENT_DATE).format('MMM DD, YYYY') || ''} ${capitalize(SingleAppointment?.APPOINTMENT_SCHEDULE_DAY) || ''}, ${SingleAppointment?.APPOINTMENT_SCHEDULE_GET_TIME}- ${Slot}`
                        })()}
                    </Grid> */}

                    <Grid
                      item
                      xs={12}
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",

                        rowGap: 0.5,
                        columnGap: 2,
                      }}
                    >
                      {/* 
                      {SingleAppointment?.APPOINTMENT_SYMPTOMS_NAME.map((row, i) => (
                        <Card container sx={{
                          borderRadius: '2px',
                          p: 1,
                          px: 1,
                          rowGap: 0.5,
                          columnGap: 1,

                        }} key={row?.name}>

                          <Typography align="left">{row?.name}</Typography>

                        </Card>

                      ))} */}
                      <Card
                        container
                        sx={{
                          borderRadius: "2px",
                          p: 1,
                          px: 1,
                          rowGap: 0.5,
                          columnGap: 1,
                        }}
                      >
                        <Typography className="payment">
                          Payment Status:
                          {capitalize(
                            SingleAppointment?.APPOINTMENT_PAYMENT_STATUS
                          )}
                        </Typography>
                      </Card>
                      <Card
                        container
                        sx={{
                          borderRadius: "2px",
                          p: 1,
                          px: 1,
                          rowGap: 0.5,
                          columnGap: 1,
                        }}
                      >
                        <Typography>
                          Total Amount : {SingleAppointment?.APPOINTMENT_AMOUNT}
                        </Typography>
                      </Card>
                      <Card
                        container
                        sx={{
                          borderRadius: "2px",
                          p: 1,
                          px: 1,
                          rowGap: 0.5,
                          columnGap: 1,
                        }}
                      >
                        <Typography>
                          Payment Source:
                          {SingleAppointment?.APPOINTMENT_PAYMENT_SOURCE}
                        </Typography>
                      </Card>
                      <Card
                        container
                        sx={{
                          borderRadius: "2px",
                          p: 1,
                          px: 1,
                          rowGap: 0.5,
                          columnGap: 1,
                        }}
                      >
                        <Typography>
                          Payment Payer Id:
                          {SingleAppointment?.APPOINTMENT_PAYER_ID}
                        </Typography>
                      </Card>
                      <Card
                        container
                        sx={{
                          borderRadius: "2px",
                          p: 1,
                          px: 1,
                          rowGap: 0.5,
                          columnGap: 1,
                        }}
                      >
                        <Typography>
                          Payment Order Id:
                          {SingleAppointment?.APPOINTMENT_ORDER_ID}
                        </Typography>
                      </Card>
                    </Grid>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      className="payment"
                    >
                      <GridActionsCellItem
                        className="ActionsCellItem"
                        icon={
                          <Iconify
                            icon={(() => {
                              if (
                                SingleAppointment?.APPOINTMENT_STATUS ===
                                "ACCEPTED"
                              ) {
                                return "ion:checkmark-done-sharp";
                              }
                              if (
                                SingleAppointment?.APPOINTMENT_STATUS === "DONE"
                              ) {
                                return "ant-design:file-done-outlined";
                              }
                              if (
                                SingleAppointment?.APPOINTMENT_STATUS ===
                                "PENDING"
                              ) {
                                return "ic:baseline-done";
                              }
                              if (
                                SingleAppointment?.APPOINTMENT_STATUS ===
                                "EXPIRED"
                              ) {
                                return "teenyicons:appointments-outline";
                              }
                              if (
                                SingleAppointment?.APPOINTMENT_STATUS ===
                                "CANCELED"
                              ) {
                                return SingleAppointment?.APPOINTMENT_CANCEL_BY ===
                                  "DOCTOR"
                                  ? "material-symbols:cancel-rounded"
                                  : "material-symbols:cancel-rounded";
                              }
                            })()}
                            sx={{ width: 20, height: 20 }}
                          />
                        }
                        label={(() => {
                          if (
                            SingleAppointment?.APPOINTMENT_STATUS === "ACCEPTED"
                          ) {
                            return "Mark Done";
                          }
                          if (
                            SingleAppointment?.APPOINTMENT_STATUS === "DONE"
                          ) {
                            return "Done";
                          }
                          if (
                            SingleAppointment?.APPOINTMENT_STATUS === "PENDING"
                          ) {
                            return "Accept";
                          }
                          if (
                            SingleAppointment?.APPOINTMENT_STATUS === "EXPIRED"
                          ) {
                            return "Reschedule";
                          }
                          if (
                            SingleAppointment?.APPOINTMENT_STATUS === "CANCELED"
                          ) {
                            return SingleAppointment?.APPOINTMENT_CANCEL_BY ===
                              "DOCTOR"
                              ? "Accept Again Same Time"
                              : `Cancelled By ${SingleAppointment?.APPOINTMENT_PATIENT_FIRST_NAME}`;
                          }
                        })()}
                        onClick={(() => {
                          if (
                            SingleAppointment?.APPOINTMENT_STATUS === "ACCEPTED"
                          ) {
                            return acceptAppoinment(
                              SingleAppointment?.APPOINTMENT_ID,
                              "DONE",
                              SingleAppointment?.APPOINTMENT_PATIENT_ID
                            );
                          }
                          if (
                            SingleAppointment?.APPOINTMENT_STATUS === "DONE"
                          ) {
                            // return acceptAppoinment(params?.row?.APPOINTMENT_ID, 'DONE', params?.row?.APPOINTMENT_PATIENT_ID)
                            // return    enqueueSnackbar("Already Done", { variant: 'info' });
                          }
                          if (
                            SingleAppointment?.APPOINTMENT_STATUS === "PENDING"
                          ) {
                            return acceptAppoinment(
                              SingleAppointment?.APPOINTMENT_ID,
                              "ACCEPTED",
                              SingleAppointment?.APPOINTMENT_PATIENT_ID
                            );
                          }
                          if (
                            SingleAppointment?.APPOINTMENT_STATUS === "EXPIRED"
                          ) {
                            return "Reschedule";
                          }
                          if (
                            SingleAppointment?.APPOINTMENT_STATUS === "CANCELED"
                          ) {
                            return SingleAppointment?.APPOINTMENT_CANCEL_BY ===
                              "DOCTOR"
                              ? "Accept Again Same Time"
                              : null;
                          }
                        })()}
                        showInMenu
                      />
                      <GridActionsCellItem
                        icon={
                          <Iconify
                            icon={
                              SingleAppointment?.APPOINTMENT_STATUS ===
                                "PENDING"
                                ? "iconoir:cancel"
                                : "wpf:recurring-appointment"
                            }
                            sx={{ width: 20, height: 20 }}
                          />
                        }
                        label={(() => {
                          if (
                            SingleAppointment?.APPOINTMENT_STATUS === "CANCELED"
                          ) {
                            return "Reschedule";
                          }
                          if (
                            SingleAppointment?.APPOINTMENT_STATUS === "ACCEPTED"
                          ) {
                            return "Reschedule";
                          }
                          if (
                            SingleAppointment?.APPOINTMENT_STATUS === "EXPIRED"
                          ) {
                            return "Reschedule";
                          }
                          if (
                            SingleAppointment?.APPOINTMENT_STATUS === "PENDING"
                          ) {
                            return "Cancel";
                          }
                        })()}
                        onClick={(() => {
                          if (
                            SingleAppointment?.APPOINTMENT_STATUS === "CANCELED"
                          ) {
                            return rescheduleAppoinment(
                              SingleAppointment?.APPOINTMENT_ID,
                              "CANCELED",
                              "DOCTOR",
                              SingleAppointment?.APPOINTMENT_PROVIDER_ID,
                              SingleAppointment?.APPOINTMENT_PATIENT_ID
                            );
                          }
                          if (
                            SingleAppointment?.APPOINTMENT_STATUS === "ACCEPTED"
                          ) {
                            return rescheduleAppoinment(
                              SingleAppointment?.APPOINTMENT_ID,
                              "CANCELED",
                              "DOCTOR",
                              SingleAppointment?.APPOINTMENT_PROVIDER_ID,
                              SingleAppointment?.APPOINTMENT_PATIENT_ID
                            );
                          }
                          if (
                            SingleAppointment?.APPOINTMENT_STATUS === "EXPIRED"
                          ) {
                            return rescheduleAppoinment(
                              SingleAppointment?.APPOINTMENT_ID,
                              "CANCELED",
                              "DOCTOR",
                              SingleAppointment?.APPOINTMENT_PROVIDER_ID,
                              SingleAppointment?.APPOINTMENT_PATIENT_ID
                            );
                          }
                          if (
                            SingleAppointment?.APPOINTMENT_STATUS === "PENDING"
                          ) {
                            return cancelAppoinment(
                              SingleAppointment?.APPOINTMENT_ID,
                              "CANCELED",
                              "DOCTOR",
                              SingleAppointment?.APPOINTMENT_PROVIDER_ID,
                              SingleAppointment?.APPOINTMENT_PATIENT_ID
                            );
                          }
                        })()}
                        className={`ActionsCellItem ${SingleAppointment?.APPOINTMENT_STATUS === "DONE"
                            ? "hide_button"
                            : null
                          }`}
                        //  label="Cancel Appointment" onClick={cancelAppoinment(params?.row?.APPOINTMENT_ID, 'CANCELED', 'DOCTOR', params?.row?.APPOINTMENT_PROVIDER_ID, params?.row?.APPOINTMENT_PATIENT_ID)}
                        showInMenu
                      />
                    </div>
                  </Box>
                );
              }
            })()}
          </DialogContent>
        </BootstrapDialog>
      </div>
    </div>
  );
};

export default ProviderAppointmentDataTable;
