import PropTypes from 'prop-types';
import { Container, Alert, AlertTitle, Button } from '@mui/material';
import useAuth from '../hooks/useAuth';
import { askUserPermission, isPushNotificationSupported } from '../push-notifications';

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.array, // Example ['admin', 'leader']
  children: PropTypes.node,
};

const useCurrentRole = () => {
  // Logic here to get current user role
  const { user } = useAuth();
  const UserRole = user?.role;

  const role = UserRole;
  return role;
};

export default function RoleBasedGuard({ accessibleRoles, children }) {
  const currentRole = useCurrentRole();
  isPushNotificationSupported();
  askUserPermission();
  // registerServiceWorker();
  if (!accessibleRoles.includes(currentRole)) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
          <br />
          <br />
          <br />
          <a href='/' style={{ textDecoration: 'none' }}>
            <Button to="/" size="large" variant="contained">
              Go to Home
            </Button>
          </a>
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
